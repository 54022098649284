.cardsInfoSection {
  &__row {
    justify-content: center;
  }
  &__card {
    padding: 0 20px;

    img {
      max-width: 36px;
      margin: 20px 0px;
    }

    &--headerText {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      letter-spacing: 0;
      line-height: 22px;
      height: 50px;
      margin-bottom: 5px;
      overflow: hidden;
    }
    &--breadText {
      @include font-size($mediumSmalTextSize);
      letter-spacing: 0;
      line-height: 16px;
      padding-bottom: 10px;
      color: $darkParagraphText;
    }
    &--breadText > * {
      @include font-size($mediumSmalTextSize);
      letter-spacing: 0;
      line-height: 16px;
      padding-bottom: 10px;
      color: $darkParagraphText;
    }
  }

  &__underCardText {
    @include margin-top(65px);
    position: relative;

    &--line {
      position: absolute;
      background-color: $headerTextRed;
      height: 50px;
      width: 2px;
      top: 50%;
      transform: translateY(-100%);
      &-left {
        left: 0;
      }
      &-right {
        right: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 33%;
      height: 2px;
      background-color: $headerTextRed;
      top: 50%;
      left: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 33%;
      height: 2px;
      background-color: $headerTextRed;
      top: 50%;
      right: 0;
    }

    &__wrapper {
      @include flexPosition(center, unset, true);
      &--header {
        text-align: center;
        color: $headerTextRed;
        @include font-size($smalHeaderText);
        font-weight: $fontWeight500;
        letter-spacing: 0;
        line-height: 27px;
      }
      &--breadText {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .cardsInfoSection {
    &__card {
      @include flexPosition(center, center, false);
      flex-direction: column;

      &--headerText {
        text-align: center;
      }
      &--breadText {
        text-align: center;
      }
    }
    &__underCardText {
      padding: 25px 0px;
      &::after {
        width: 23%;
      }
      &::before {
        width: 23%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cardsInfoSection {
    &__row {
      margin-bottom: 20px;
    }
    &__underCardText {
      &--line {
        display: none;
      }
      &::after {
        left: 0%;
        top: 0%;
        width: 80%;
        transform: translateX(10%);
      }
      &::before {
        left: 0;
        top: 100%;
        width: 80%;
        transform: translateX(10%);
      }
    }
    &__underCardText__wrapper--breadText {
      text-align: center;
    }
  }
}
