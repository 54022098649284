.link-card {
  @include padding-bottom(50px);
  &__large {
    .link-card__container {
      border-bottom: 1px solid $borderColor !important;
      padding: 25px 15px;
      &:not(:last-child){
        border-bottom: none;
      }
    }
    p {
      @include font-size($largeHeaderText);
      line-height: 32px;
      color: $darkHeaderTextColor;
    }
    span::after {
      @include font-size($largeHeaderText);
    }
    .link-card__item-date {
      margin-top: 12px;
    }
  }

  &__title {
    color: $headerTextRed;
    letter-spacing: 0;
    line-height: 32px;

    h1,
    h2 {
      @include font-size($bigHeaderText);
      padding-left: 18px;
    }
    h3 {
      @include font-size($largeHeaderText);
      font-weight: 400;
    }
  }
  &__wrapper {
    @include margin-top(20px);
  }
  &__container {
    display: flex;
    padding: 17px 15px;
    transition: all 0.3s ease-in;
    height: 136px;
    border: 1px solid $borderColor;
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
  }

  &__item {
    width: 95%;
    @include flexPosition(unset, unset, true);
    &-title {
      &--big {
        @include font-size($smalHeaderText);
        padding-top: 6px;
        color: $darkGreyColor;
      }
    }
    &-date {
      margin-top: 5px;
      span {
        color: $darkParagraphText;
        @include font-size($mediumSmalTextSize);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }
  &__arrow {
    width: 5%;
    @include flexPosition(flex-end, center, false);
  }

}

.pressRelease__sticky {
  position: sticky;
  top: 150px;
  z-index: 1;
}

.mfn-content {
  padding: 0;

  .mfn-year-header {
    margin: 1rem 0;
    letter-spacing: 0 !important;
    line-height: normal !important;
    font-weight: $fontWeight400 !important;
    color: $headerTextRed !important;
    @include font-size($bigHeaderText!important);
    @media only screen and (max-width: 600px) {
      padding: 0 15px;
    }
  }
  width: 100%;
  @include font-size($largeHeaderText);
  line-height: 32px;
  color: $darkHeaderTextColor;
  a {
    color: $darkHeaderTextColor;
  }
  .mfn-tags-list {
    display: flex;
    flex-wrap: wrap;
  }
  .mfn-tag,
  .mfn-year {
    color: $darkHeaderTextColor;
    margin-right: 0.5rem;
  }
  .mfn-date {
    color: $darkParagraphText;
    @include font-size($smalTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 16px;
  }
}
.mfn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .mfn-tags,
  .mfn-year {
    flex-basis: 33%;
    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      padding: 0 15px;
    }
  }
}
.event_title,
.event_links a,
.mf_description,
.event_date {
  color: $darkParagraphText;
  display: flex;
  align-items: center;
  @include font-size($mediumTextSize);
  letter-spacing: normal;
  line-height: 19px;
  font-weight: 300;
}

.mfn-pagination{
  padding: 1rem;
  text-align: end;
}

html[lang="en"] {
  .mfn-date::after, div.event_date::after, td.event_date::after {
    content: 'CET';
    margin-left: 7px;
  
  }
}
