.redcontainer-textcolumn {
  .row {
    padding: 20px 0px;
  }
  &__wrapper {
    @include flexPosition(unset, center, false);
  }
  .container {
    background: $backgroundRedGradient;
    padding: 50px 15px;
  }
  &__container {
    &-title {
      letter-spacing: 0;
      line-height: 38px;
      color: $whiteTextColor;
    }
    &-title > * {
      letter-spacing: 0;
      line-height: 38px;
      color: $whiteTextColor;
    }
    &-text {
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
      color: $whiteTextColor;
      margin-bottom: 15px;
    }
    &-text > * {
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
      color: $whiteTextColor !important;
    }
  }
  &__right {
    @include flexPosition(flex-end, center, false);
  }
  form {
    width: 100%;

    .form__error {
      left: 5px !important;
    }
  }
}

@media (max-width: 1399px) {
  .redcontainer-textcolumn {
    &__button {
      margin-top: 25px;
    }
  }
}

@media (max-width: 768) {
  .redcontainer-textcolumn {
    .container {
      background: $backgroundRedGradient;
      padding: 0px 15px;
    }
  }
}
