.text-component {
  h2 {
    width: 65%;
    @include font-size($bigHeaderText !important);
    color: $headerTextRed !important;
  }
  &__IR {
    h2 {
      width: 100%;
      color: $headerTextRed !important;
    }
    h3 {
      color: $headerTextRed !important;
      text-transform: uppercase;
      letter-spacing: 0;
      margin-bottom: 12px !important;
    }
  }
  h3 {
    color: $headerTextRed !important;
    @include font-size($largeHeaderText !important);
    letter-spacing: 0;
    margin-bottom: 12px !important;
  }

  @media only screen and (max-width: 991px) {
    .col-lg-4{
      @include margin-bottom(10px);
    }
    &__IR {
      .col-lg-4{
        @include margin-bottom(16px);
      }
    }
    h2 {
      width: 100%;
    }
  }
}
