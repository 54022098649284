.textWithImage {
  &__text {
    @include flexPosition(unset, center, false);
    flex-wrap: wrap;
    &__header {
      padding-bottom: 20px;
      h3 {
        color: $headerTextRed;
        @include font-size($bigHeaderText);
        letter-spacing: 0;
        line-height: 40px;
      }
    }
    &__body {
      p {
        color: $mediumDarkTextColor;
        @include font-size($smalHeaderText);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 27px;
      }
    }
    &__button {
      @include padding-top(40px);
    }
  }

  &__image {
    img {
      max-width: 100%;
    }
  }
}
