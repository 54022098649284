.filter {
  position: relative;
  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 22px;
    background-color: $borderColor;
    z-index: 0;
  }
  @include margin-bottom(50px);
  &__title {
    @include margin-bottom(50px);
    @include flexPosition(space-between, center, false);
    flex-wrap: wrap;
    h2 {
      color: $headerTextRed;
      width: 70%;
    }
    &-response {
      color: $darkParagraphText;
    }
  }
  &__container {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: "OR";
        position: absolute;
        right: 1px;
        bottom: 13px;
        background: #fff;
        text-transform: uppercase;
        color: $headerTextRed;
        @include font-size($mediumSmalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 1.5px;
      }
    }
  }
  &__select {
    position: relative;
    width: 95%;
  }
  &__select-text {
    @include font-size($mediumSmalTextSize);
    padding-bottom: 10px;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__select-selector {
    position: relative;
    background-color: $lightBackgroundColor;
    &-choosed {
      border: 1px solid $borderColor;
      @include flexPosition(space-between, unset, false);
      cursor: pointer;

      span {
        padding: 10px;
      }
      &--text {
        color: $darkParagraphText;
        @include font-size($mediumTextSize);
        font-weight: $fontWeight300;
        letter-spacing: 0;
      }
      &--icon {
        &::after {
          content: "\f107";
          font-family: "Font Awesome\ 5 Free";
          display: inline-block;
          font-weight: $fontWeight600;
          color: $darkParagraphText;
          padding-left: 5px;
          @include font-size($mediumTextSize);
        }
      }
    }

    &-dropdown {
      position: absolute;
      width: 100%;
      max-height: 230px;
      overflow-y: auto;
      display: none;
      border: 1px solid $borderColor;
      padding: 10px;
      z-index: 1050;
      background-color: #fff;
      &--container {
        position: relative;
        &-title {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: $darkParagraphText;
          @include font-size($mediumTextSize);
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px;
        }
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .filter__select-selector-dropdown--container-checkmark {
            background-color: $headerTextRed;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .filter {
    margin: 0px;
    &::before {
      display: none;
    }

    &__select-selector {
      @include margin-bottom(50px);
    }
    &__container {
      &:not(:last-child) {
        &::after {
          left: 15px;
          bottom: 5%;
        }
      }
    }
  }
}
