.history {
  &__column {
    @include flexPosition(unset, unset, true);
  }
  .history__container {
    @include flexPosition(flex-end, unset, false);
    width: 100%;
    border-left: 3px solid $headerTextRed;
    position: relative;
    &::before {
      content: "\f111";
      font-family: "Font Awesome\ 5 Free";
      position: absolute;
      display: block;
      top: -9px;
      left: -16.5px;
      font-size: 30px;
      font-weight: $fontWeight600;
      color: $headerTextRed;
      z-index: 105;
    }
    &:last-child {
      @include padding-bottom(100px);
      &::after {
        content: "\f0d7";
        font-family: "Font Awesome\ 5 Free";
        position: absolute;
        display: block;
        left: -16px;
        bottom: -30px;
        font-weight: $fontWeight600;
        color: $headerTextRed;
        font-size: 46px;
        z-index: 105;
      }
    }
  }
  &__text {
    margin-top: -4px;
    width: 85%;
    &-text {
      padding-top: 10px;
      @include padding-bottom(50px);
      color: $mediumDarkTextColor !important;
      @include font-size($smalHeaderText);
      line-height: 27px;      
    }
    &-text > * {
      color: $mediumDarkTextColor !important;
      @include font-size($smalHeaderText);
      line-height: 27px;
    }
  }
}

@media (max-width: 440px) {
  .history {
    .container {
      max-width: 95%;
    }
  }
}
