body {
  .container {
    max-width: 1400px;
  }
  @media (max-width: 1399px) {
    #wrapper {
      overflow-x: hidden;
      max-width: 100%;
    }
    .container {
      max-width: 95%;
    }
  }
  @media (max-width: 767px) {
    .container {
      max-width: 100%;
    }
  }
  @media (max-width: 989px) {
    .right__column {
      @include margin-top(40px);
    }

    .rich__text {
      a + a {
        margin-left: 0px !important;
      }
    }
  }
  @media (max-width: 576px) {
    .container {
      max-width: 100%;
    }
  }
}
