.logo-card {
  display: flex;
  flex-wrap: wrap;
  &__wrapper {
    width: 100%;
    padding: 20px;
    padding-bottom: 10px;
    border: 1px solid $borderColor;
    max-width: 440px;
    min-width: 430px;
    margin-bottom: -1px;
  }
  &__header {
    @include flexPosition(space-between, unset, false);
    &-logo {
      img {
        max-height: 50px;
        width: auto;
      }
    }
    &-date {
      width: 30%;
      @include flexPosition(flex-end, unset, false);
      span {
        color: $darkParagraphText;
        @include font-size($mediumSmalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 0;
        line-height: 16px;
        text-align: right;
      }
    }
  }

  &__body {
    &-name {
      padding-top: 10px;
      padding-bottom: 20px;
      h2 {
        color: $darkHeaderTextColor;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
        line-height: 27px;
      }
    }
    &-info {
      @include flexPosition(space-between, unset, false);
      p {
        color: $darkParagraphText;
        @include font-size($mediumSmalTextSize);
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }
}
.logoCardRow {
  .logo-card {
    width: 100%;
  }
}

@media only screen and (max-width: 490px) {
  .logo-card {
    width: 100%;
    &__wrapper {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
  .logoCardRow {
    &__wrapper {
    }
  }
}

@media only screen and (max-width: 957px) {
  .logoCardRow {
    .logo-card {
      &__wrapper {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}

.filter__events {
  .logo-card__wrapper {
    @include margin-bottom(30px);
  }
}
