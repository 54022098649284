.subscribe-field {
  width: 100%;
  a {
    @include flexPosition(center, unset, false);
  }
  &__text {
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 8px;

    input {
      width: 100%;
      height: inherit;
      padding-left: 16px;
      outline: none;
      border: none;
    }
    &:first-child {
      &::after {
        content: "\f0e0";
        font-family: $FontAwesome;
        display: block;
        position: absolute;
        right: 14px;
        top: 8px;
        font-weight: $fontWeight600;
        padding-left: 10px;
        font-size: 18px;
        color: $headerTextRed;
        z-index: 100;
      }
    }
  }
  button {
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .subscribe-field {
    margin: 30px 0px;
  }
}
