.text-col-video {
  &__title {
    color: $headerTextRed;
    @include margin-bottom(60px);
  }
  &__wrapper {
    @include flexPosition(space-between, unset, false);
    flex-wrap: wrap;
  }
  &__container {
    width: 40%;
    @include margin-bottom(43px);
    &-title {
      h3 {
        color: $darkHeaderTextColor;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
        line-height: 27px;
      }
    }

    &-text {
      @include margin-top(10px);
    }
  }

  &__video-info {
    @include margin-top(20px);
    color: $darkParagraphText;
    @include font-size($mediumSmalTextSize);
    letter-spacing: 0;
    line-height: 16px;
  }

  iframe {
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .text-col-video {
    &__container {
      width: 100%;
    }
  }
}
