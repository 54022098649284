.boxed-cta {
  &__container {
    width: 50%;
    padding: 0px !important;

    img {
      width: 100%;
    }
  }

  &__border {
    border: 1px solid $redBorderColor;
    border-right: none;
    padding: 65px !important;
    @include flexPosition(space-around, flex-start, true);

    &-text {
      width: 80%;

      h3 {
        color: $darkHeaderTextColor;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    &__border {
      border: none;
      padding: 0px !important;
      align-items: center;

      &-text,
      &-button {
        width: 80%;
        margin-bottom: 20px;
        @include margin-bottom(20px);
      }
    }
  }
}
