.iconRow {
  display: flex;
  width: 100%;
  &__icon {
    position: relative;
    margin-right: 16px;
    width: 40px;
    background-color: $grayBackgroundColor;
    border: 1px solid $grayBorderColor;
    border-radius: 1px;
    padding: 0px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 150ms ease-in;

    &:focus {
      outline: none !important;
    }

    &:before {
      font-family: $FontAwesomeBrands;
      @include font-size($mediumTextSize);
      text-align: center;
      height: 40px;
      text-decoration: none;
      display: block;
      color: $darkGreyColor;
      line-height: 40px;
      font-weight: $fontWeight900;
    }
    &[class*="facebook"]:before,
    &-facebook:before {
      content: "\f39e";
    }
    &[class*="twitter"]:before,
    &-twitter:before {
      content: "\f099";
    }
    &[class*="linkedin"]:before,
    &-linkedin:before {
      content: "\f0e1";
    }
    &[class*="instagram"]:before,
    &-instagram:before {
      content: "\f16d";
    }
    &[class*="youtube"]:before,
    &-youtube:before {
      content: "\f16a";
    }
    &[class*="vimeo"]:before,
    &vimeo:before {
      content: "\f27d";
    }
    &[class*="mailto"]:before,
    &-email:before {
      font-family: $FontAwesome;
      content: "\f0e0";
    }
    &-print:before {
      font-family: $FontAwesome;
      content: "\f02f";
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}

.right__column {
  .flex-column {
    .iconRow__icon {
      margin-top: 16px;
      margin-left: 65%;
    }
  }
}
@media (max-width: 440px) {
  .iconRow {
    margin-left: 0px !important;
  }
}
