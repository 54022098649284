//Responsive header and dropdown imports at bottom
.header {
  background-color: $lightBackgroundColor;
  z-index: 1040;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  .nav {
    padding: 0px;
    .active {
      span,
      a {
        color: $headerTextRed;
      }
    }

    &__row {
      position: relative;
      width: 100%;
      justify-content: space-between;
      padding-left: 15px;
    }
    &__logo-wrapper {
      @include flexPosition(unset, center, false);
      padding: 10px 15px;
      z-index: 1;

      &.avenue{
        padding: 20px 15px;
      }
    }

    &__logo-image {
      display: block;
      width: 100px;
      height: auto;
      transform: scale(1);
      transition: transform 500ms ease-in;

      &.avenue{
        width: auto;
        height: 30px;
      }
    }
    &__logo-image--shrink {
      transform: scale(0.7);
      margin: 0px;
      &.avenue{
        transform: scale(0.85);
      }
    }

    &__navbar {
      position: absolute;
      width: auto;
      top: 0;
      left: 15px;
      right: 0;
      margin: 0 auto 0 auto;
      @include font-size($smalTextSize);
      height: 100%;
      width: 100%;
      @include flexPosition(unset, center, false);

      ul {
        margin: 0;
        width: 100%;
        list-style-type: none;
        display: flex;
        position: relative;
        justify-content: center;
        @include flexPosition(center, unset, false);

        li {
          display: flex;
          margin-right: 24px;
          white-space: nowrap;
          cursor: pointer;
          @include font-size($textSize);
          &:hover {
            span {
              color: $headerTextRed;
            }
          }
          a {
            color: $darkParagraphText;
            text-decoration: none;
          }
        }
      }
      &-hasdropdown {
        white-space: nowrap;
        align-items: center;
        &::after {
          content: "\f107";
          font-family: "Font Awesome\ 5 Free";
          display: block;
          font-weight: $fontWeight600;
          padding-left: 10px;
          @include font-size($mediumSmalTextSize);
        }

        &:hover {
          color: $headerTextRed;
        }
      }
    }

    &__search {
      width: 800px;
      @include flexPosition(center, center, false);
      background-color: #fff;
      left: 25%;
      top: 0;
      height: 100%;
      position: absolute;
      &-wrapper {
        width: 100%;
        height: 100%;
        @include flexPosition(center, center, false);
        position: relative;
      }
      &-icon,
      &-text-container,
      &-close-container {
        height: 100%;
        @include flexPosition(unset, center, false);
        position: relative;
      }

      &-icon,
      &-close-container {
        @include flexPosition(flex-end, center, false);
        cursor: pointer;
      }
      &-close-container {
        position: absolute;
        right: 0px;
        width: 73px;
        @include flexPosition(center, center, false);
        background: #fff;
      }
      &-icon {
        &::before {
          content: "\f002";
          display: block;
          font-family: "Font Awesome\ 5 Free";
          font-weight: $fontWeight600;
          color: $headerTextRed;
          @include font-size($largeHeaderText);
        }
      }

      &-close-container {
        &::after {
          content: "\f00d";
          display: block;
          font-family: "Font Awesome\ 5 Free";
          font-weight: $fontWeight600;
          color: $headerTextRed;
          @include font-size($largeHeaderText);
        }
      }

      &-container {
        width: 90%;
        &-text-field {
          width: 100%;
          @include font-size($mediumTextSize);
          padding: 10px 10px 10px 10px;
          letter-spacing: 0;
          line-height: 22px;
          border: none;
          outline: none;
          background-color: #fff;
        }
      }
      &-close-container {
        img {
          filter: $svgDarkParagraphText;
        }
      }

      &-dropdown {
        position: absolute;
        width: 100%;
        background-color: $backGroundColorDropDown;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        top: 56px;
        z-index: 10000;

        &-row {
          height: auto;
          width: 100%;
          border-bottom: 1px solid $borderColor;
          margin-top: 20px;
          padding-bottom: 20px;

          h3 {
            color: $lightHeaderColor;
            padding: 0px 0px 0px 5px;
            @include font-size($mediumSmalTextSize);
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0px;

            li {
              padding: 10px 0px 10px 5px;
              cursor: pointer;
              &:hover {
                a {
                  color: $headerTextRed;
                }
              }
              a {
                text-decoration: none;
                color: $darkParagraphText;
                @include font-size($mediumTextSize);
                font-weight: $fontWeight300;
                letter-spacing: 0;
                line-height: 22px;
              }
            }
          }
        }
        &-title {
          height: 20px;
        }
      }
    }
    &__fixed {
      position: absolute;
      right: 0;
      display: flex;
    }
    &__info {
      &-search,
      &-language-container {
        height: 100%;
        @include flexPosition(unset, center, false);
      }
      &-search {
        padding-right: 10px;
        &-button {
          position: relative;
          cursor: pointer;
          &::before {
            transition: transform 150ms ease-in;
            content: "\f002";
            display: block;
            font-family: "Font Awesome\ 5 Free";
            font-weight: $fontWeight600;
            font-size: 16px;
          }
          &:hover::before {
            transform: scale(1.3);
          }
        }

        input {
          width: 20px;
        }
      }
      &-language {
        position: relative;
        width: 100%;
        border-left: 1px solid $borderColor;
        &-container {
          position: relative;
          cursor: pointer;
        }

        &-notice {
          position: absolute;
          height: 82px;
          width: 242px;
          top: 80px;
          background-color: $redBackgroundColor;
          z-index: 1600;
          padding: 20px;

          &::after {
            content: "\f0d7";
            transform: rotate(180deg);
            font-family: "Font Awesome\ 5 Free";
            position: absolute;
            display: block;
            right: 38px;
            top: -35px;
            font-weight: $fontWeight600;
            color: $redBackgroundColor;
            font-size: 46px;
          }

          &-container {
            p {
              color: $whiteTextColor;
              @include font-size($mediumTextSize);
              letter-spacing: 0;
              line-height: 22px;
              position: relative;
              padding-left: 30px;
              margin: 0;

              &::before {
                content: "\f7a2";
                transform: rotate(180deg);
                font-family: "Font Awesome\ 5 Free";
                position: absolute;
                display: block;
                font-weight: $fontWeight600;
                color: $whiteTextColor;
                left: -5px;
                top: 8px;
                font-size: 24px;
              }
            }
          }
        }
      }
      &-dropdown-icon {
        width: 20px;
      }
      img {
        max-width: 16px;
      }

      &-selected-language {
        @include flexPosition(space-between, unset, false);
        width: 74px;
        margin-left: 10px;
        ul {
          padding: 0px;
        }

        span {
          color: $darkParagraphText;
          @include font-size($textSize);
          font-weight: $fontWeight400;
          text-decoration: none;
          display: flex;
          &::after {
            content: "\f107";
            font-family: "Font Awesome\ 5 Free";
            display: inline-block;
            font-weight: $fontWeight600;
            padding-left: 15px;
            font-size: 14px;
          }
        }
      }
      &-dropdown {
        position: absolute;
        display: none;
        top: 47px;
        width: auto;
        padding: 10px;
        background-color: #f7f2f2;
        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
        z-index: 1041;
        overflow: hidden;
        padding-bottom: 10px;

        ul {
          list-style-type: none;
        }
        li {
          a {
            display: block;
            padding: 10px 10px 10px 16px;
            @include font-size($textSize);
            letter-spacing: 0;
            line-height: 16px;
            cursor: pointer;
            text-decoration: none;
            color: $darkParagraphText;
            &:hover {
              color: $headerTextRed;
            }
          }
        }
      }
    }
  }
  .language {
    &__dropdown {
      display: none;
      position: absolute;
      width: 100vw;
      right: -20px;
      top: 52px;
      background-color: #f7f2f2;
      box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
      z-index: 1030;
      overflow: hidden;
      padding-bottom: 10px;
      max-height: 100vh;
      ul {
        list-style-type: none;
      }
      li {
        display: flex;
        justify-content: center;
        @include font-size($mediumTextSize);
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid $borderColor;
        }
        a {
          padding: 20px 0px;
          width: 100%;
          text-align: center;
          text-decoration: none;
          color: $darkParagraphText;
        }
      }
    }
  }
}

@import "./_dropDown.scss";
@import "./_responsiveHeader.scss";
