.headerselecttext {
  &__header-text {
    padding-bottom: 20px;
    h3 {
      color: $headerTextRed;
      @include font-size($smalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 2px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }

  &__select-button {
    padding-bottom: 20px;
    h2 {
      color: $darkHeaderTextColor;
      opacity: 0.2;
      cursor: pointer;
      transition: all ease 500ms;
      line-height: 35px;
    }

    &--active {
      h2 {
        opacity: 1;
      }
    }
  }
  &__select-text--collapse {
    display: none;
    cursor: pointer;
    &::after {
      content: "+";
      font-size: 22px;
      padding-left: 5px;
      transition: all 0.3s ease-in;
    }
    &--active {
      &::after {
        content: "-";
      }
    }
  }
  &__select-text {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    min-height: 330px;

    &--active {
      display: flex;
    }

    &__container {
      width: 50%;
      padding: 10px 15px;
      opacity: 0;
      &__header {
        h3 {
          color: $darkHeaderTextColor;
          @include font-size($smalHeaderText);
          letter-spacing: 0;
          line-height: 27px;
        }
      }
      &__text {
        a {
          color: $headerTextRed;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 989px) {
  .headerselecttext {
    &__container {
      @include flexPosition(space-between, unset, false);
    }

    &__select-text__container {
      width: 100% !important;
    }
  }
}

@media (max-width: 989px) {
  .headerselecttext {
    &__container {
      @include flexPosition(unset, unset, true);
    }
    &__select-button {
      padding-bottom: 20px;
      h2 {
        color: $darkHeaderTextColor;
        opacity: 1;
        cursor: pointer;
        transition: all ease 500ms;
        width: 80%;
      }
    }
    &__select-text {
      max-height: 1000px;
      display: none;

      &__container {
        opacity: 1;
      }
    }
    &__select-text--active {
      display: none;
    }
    &__select-button {
      @include flexPosition(space-between, unset, false);
      flex-wrap: wrap;
    }
    &__select-text--collapse {
      display: block;
    }
  }
}
