.table-responsive {
  font-size: 11px !important;
  table {
    width: 0.1%;
    white-space: nowrap;
  }
  .tablePdf__image-wrapper {
    display: block;
    width: auto;
    max-width: 250px;
    @include margin-bottom(32px);
  }
  .tablePdf__icon {
    &::after {
      content: "\f1c1";
      font-family: $FontAwesome;
      padding-top: 2px;
      font-weight: $fontWeight600;
      font-size: 12px;
      color: $headerTextRed;
    }
  }
  a {
    display: block;
    transition: all 0.2s ease;
    color: $darkParagraphText !important;
    text-decoration: none !important;
    &:hover {
      color: $headerTextRed !important;
    }
  }
  a,
  th,
  td {
    color: $darkParagraphText;
    font-weight: $fontWeight400;
    img {
      margin: 0px !important;
    }
  }
  th,
  td {
    padding: 4px !important;
  }
  .tablePdf__title {
    margin-left: 5px;
    font-weight: $fontWeight600 !important;
  }
}
table{
  table-layout: auto !important;
}