.text-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__text {
    margin-top: 12px;
    width: 100%;
  }

  h2 {
    text-align: left;
    margin: 0px;
  }

  &__title-tiny {
    font-size: $smalTextSize !important;
    font-weight: $fontWeight600 !important;
    color: $headerTextRed;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  &__optional {
    color: $darkHeaderTextColor !important;
    text-transform: none !important;
  }
  p {
    margin-top: 6px;
  }
}

@media only screen and (max-width: 769px) {
  .text-title {

    &__text-container {
      width: 100%;
      max-width: 100%;
    }
  }
}
