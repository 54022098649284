@font-face {
  font-family: "Inter Thin";
  font-display: swap;
  src: url('/Fonts/Inter-Thin.ttf');
}
@font-face {
  font-family: "Inter Regular";
  font-display: swap;
  src: url('/Fonts/Inter-Regular.ttf');
}
@font-face {
  font-family: "Inter Medium";
  font-display: swap;
  src: url('/Fonts/Inter-Medium.ttf');
}
@font-face {
  font-family: "Inter Bold";
  font-display: swap;
  src: url('/Fonts/Inter-Bold.ttf');
}

* {
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
}
// I remove preload class after page load.
// if i dont use this you will see the transistion on page load.
// Contact flyout for example.
.preload * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

body {  
  background-color: #dcdcdc !important;
  width: inherit;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  
  #wrapper {
    max-width: 1650px;
    margin: 0 auto !important;
    color: $darkParagraphText;
    position: relative;
    background-color: $whiteBackgroundColor;
  }

  .nolato__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(48, 32, 32, 0.76);
    z-index: 1660 !important;
    display: none;
  }

  .red__title{
    &-tiny{
      color: $headerTextRed !important;
      font-size: $smalTextSize !important;
      font-weight: $fontWeight600 !important;
      text-transform: uppercase !important;
      letter-spacing: 2px;
    }
    &-big{
      @include font-size($largeHeaderText !important);
      letter-spacing: 0 !important;
      line-height: normal !important;
      font-weight: $fontWeight400 !important;
      color: $headerTextRed !important;
    }
  }

  .nolato__overlay-color {
    background-color: rgba(48, 32, 32, 0.76);
  }

  .nolato__close-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    display: none;
  }
  .invalid-tooltip {
    background-color: $headerTextRed;
  }

  .right__column {
    section:not(:first-child) {
      @include margin-top(60px);
    }
    img {
      object-fit: cover;
    }
    .text-title + .contact-card{
      margin-top: 0;
    }
  }
  .display-none {
    display: none !important;
  }

  .nolato__active {
    display: block !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: $headerTextRed;
    &:hover {
      text-decoration: none;
      color: unset;
    }
  }
  .nolato__uppercase-red-text {
    text-transform: uppercase;
    color: $headerTextRed;
    @include font-size($smalTextSize);
    font-weight: $fontWeight600;
    letter-spacing: 1.5px;
    line-height: 16px;
  }
  .nolato__uppercase-red-text_text-with-red-arrow-right_link__hover{
    @extend .nolato__uppercase-red-text;
    @extend .text-with-red-arrow-right;
    @extend .link__hover;
    color: $headerTextRed !important;
  }
  .select {
    position: relative;
    select {
      width: 100%;
      height: 40px;
      padding: 0px 16px;
      border: 1px solid $borderColor;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
    }
    select,
    option {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      letter-spacing: 0;
      line-height: 22px;
    }

    &::after {
      content: "\f107";
      font-family: $FontAwesome;
      position: absolute;
      font-weight: $fontWeight600;
      color: $GrayColor;
      right: 15px;
      top: 8px;
      pointer-events:none;

      @include font-size($mediumTextSize);
    }
  }
  h1 {
    @include font-size($extraBigHeaderText);
    letter-spacing: 0;
    line-height: normal;
    font-weight: $fontWeight400;
  }

  h2 {
    @include font-size($headerText30);
    letter-spacing: 0;
    line-height: normal;
    font-weight: $fontWeight400;
    color: $headerTextRed;
  }
  h3 {
    @include font-size($font-size-6);
    font-weight: $fontWeight400;
    line-height: normal;
  }
  h4 {
    @include font-size($smalHeaderText);
    line-height: normal;
  }
  p {
    color: $darkParagraphText;
    font-size: $mediumTextSize;
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0px;
  }
  strong {
    font-weight: bold !important;
    @include margin-bottom(5px);
    display: block;
  }
  .color-dark{
    color:$darkHeaderTextColor;
  }

  @mixin padding-top($value) {
    @include padding-top($value);
  }

  @mixin padding-bottom($value) {
    @include padding-bottom($value);
  }

  @mixin margin-top($value) {
    @include maring-top($value);
  }
  @mixin padding($value) {
    @include padding($value);
  }

  @mixin margin-bottom($value) {
    @include margin-bottom($value);
  }

  .text-align {
    &__center {
      text-align: center;
    }
    &__end {
      text-align: end;
    }
    &__start {
      text-align: start;
    }
  }
.container{
  .container{
    max-width: 100%;
  }
}
  .splitter-col {
    .container,
    .row,
    div[class*="col"] {
      padding: 0;
      margin: 0;
      border: none !important;
    }
  }
  h3 {
    text-transform: uppercase;
  }
}

.text {
  &__style {
    &-h1 {
      @include font-size($extraBigHeaderText);
      letter-spacing: 0;
      line-height: normal;
      font-weight: $fontWeight400;
    }  
    &-h2 {
      @include font-size($headerText30);
      letter-spacing: 0;
      line-height: normal;
      font-weight: $fontWeight400;      
    }
    &-h3 {
      @include font-size($font-size-6);
      font-weight: $fontWeight400;
      line-height: normal;
    }
    &-h4 {
      @include font-size($smalHeaderText);
      line-height: normal;
    }
    &-uppercase {
      text-transform: uppercase;
    }
    &-preamble {
      font-size: 14px;
      text-transform: uppercase;
      color: $headerTextRed;
      font-weight: 600;
      letter-spacing: 2px;
      margin: 0;
    }
  }
  &__color {
    &-red {
      color: $headerTextRed;
    }
    &-white {
      color : #fff;
    }
    &-dark {
      color: $darkParagraphText;
    }
  }
}




.table-wrapper {
  display: block;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    white-space:nowrap
  }
}

table {

  width: 100%;

  & tr {
    border-bottom: 1px solid $headerTextRed;

    & td {      
      font-size: .875rem;
      font-weight: 400;
      padding: 0.2rem 20px;
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";

      &:first-child {
        padding-left: 0.2rem;
      }
      &:last-child {
        padding-right: 0.2rem;
      }
    }
  }
}