.toolbar {
  background-color: $darkParagraphText;
  border-bottom: $headerTextRed 3px solid;
  height: 40px;
  overflow: hidden;
  transition: height 500ms ease-in;
  transform-origin: top;

  .menubutton__hero {
    height: auto;
    animation: nolatoFadeIn 2s;

    a {
      background-color: $headerTextRed;
      padding: 6px 10px;
      color: #fff !important;
    }
    &:hover {
      border: none;
      filter: brightness(110%);
      a {
        color: #fff !important;
      }
    }
  }
  &__shrink {
    height: 0px;
    border: none;
  }

  .container {
    padding-bottom: 0;
    padding-top: 0;
    height: 100%;
  }
  &__row {
    height: 100%;
  }
  &__col {
    @include flexPosition(unset, center, false);
  }
  &__income {
    a {
     display: block;
    }
  }
  &__ticker{
    ul{
      display: flex;
      li{
        color: $whiteTextColor;
        @include font-size($mediumSmalTextSize);
        letter-spacing: 0;
        font-weight: 500;
        &:not(:last-child){
          &::after{
            content: "|";
            display: inline;
            margin: 0 0.5rem;
          }
        }
      }
    }

    .increase{
    color: $greenColor;
    }
    .decrease{
      color: $headerTextRed;
    }
  }
  &__contact {
    width: 100%;
    ul {
      @include flexPosition(flex-end, unset, false);

      li {
        display: inline;
        padding-left: 30px;
        cursor: pointer;
        text-transform: uppercase;

        a {
          @include font-size($mediumSmalTextSize);
          text-decoration: none;
          color: $whiteTextColor;
          letter-spacing: 0;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and(max-width:1025px) {
  .toolbar {
    display: none;
  }
}
