.countries-text-images {
  &__headertext {
    h2 {
      color: $headerTextRed;
    }
  }
  &__bodytext {
    padding-top: 20px;
    @include margin-bottom(30px);
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
  }
  .col-lg-3 {
    position: relative;
    overflow: hidden;
    @include margin-top(40px);

    &:nth-child(n + 5) {
      @include margin-top(30px);
    }
    &:hover {
      .countries-text-images__image {
        transform: scale(1.2);
      }
    }
  }
  &__container {
    height: 281px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  &__image {
    background-image: url("../assets/images/stockholm.jpg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.6s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }
  }
  &__imagetext {
    position: absolute;
    width: 90%;
    left: 50px;
    bottom: 25px;

    h3 {
      color: $whiteTextColor;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      line-height: 32px;
    }
    span {
      color: $whiteTextColor;
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 27px;
    }
  }
}
