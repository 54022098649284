.tickerComponent {
  @include padding-top(32px);
  @include padding-bottom(32px);
  @include flexPosition(space-between, unset, false);
  flex-wrap: wrap;
  &__col-1 {
    flex-basis: 75%;
    @include flexPosition(space-between, center, false);
    padding-right: 1rem;
    @media (max-width: 782px) {
      flex-basis: 100%;
      flex-wrap: wrap;
    }
    @media (max-width: 400px) {
      align-items: flex-end;
    }
  }
  &__col-2 {
    flex-basis: 25%;
    @include flexPosition(flex-end, center, false);
    flex-wrap: wrap;
    @media (max-width: 782px) {
      flex-basis: 100%;
    }
    @media (max-width: 400px) {
      margin-top: 1rem;
      justify-content: center;
    }
  }
  &__stockSymbols {
    &--title {
      color: $headerTextRed;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0;
    }
    &--stock {
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      color: $mediumDarkTextColor;
      line-height: 27px;
    }
  }
  &__latestPrice {
    margin: 0 1rem;
    &--title {
      color: $headerTextRed;
      @include font-size($extraBigHeaderText);
      letter-spacing: 0;
    }
    @media (max-width: 400px) {
      width: auto;
    }
  }

  &__deltatoday {
    display: flex;
    &--icon {
      margin: 0 0.5rem;
      background-color: $greenColor;
      position: relative;
      @include flexPosition(center, center, false);
      border-radius: 50%;
      height: 24px;
      width: 24px;
      &::after {
        font-size: 15px;
        content: "\f077";
        font-family: $FontAwesome;
        display: inline-block;
        font-weight: $fontWeight600;
        color: $whiteTextColor;
      }
    }
    &--decrease {
      background-color: $headerTextRed;
      &::after {
        margin-top: 2px;
        content: "\f078";
      }
    }
    &--delta {
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      color: $mediumDarkTextColor;
      line-height: 27px;
    }
  }
  &__info {
    &--text {
      font-family: Inter;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
      text-align: right;
      @media (max-width: 400px) {
        text-align: left;
      }
    }
  }
}
