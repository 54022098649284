.order-publications {
  &__title {
    @include margin-bottom(60px);
    h3 {
      color: $darkHeaderTextColor;
      @include font-size($headerText30);
      letter-spacing: 0;
      line-height: 38px;
    }
  }
  &__items {
    margin-bottom: 20px;
    padding-bottom: 20px;
    &-title {
      color: $darkParagraphText;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
    }
    ul {
      @include margin-top(16px);
      position: relative;
    }
  }
  &__item {
    margin-bottom: 10px;
    &-text {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
    }
  }

  &__info-text {
    width: 50%;
    @include margin-top(30px);
    p {
      @include margin-top(10px);
    }
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .contact-modal {
    @include margin-top(20px);
    .container {
      padding: 0px;
    }
  }
}
