.hotSpots {
  width: 100%;
  overflow: hidden;

  .container {
    border: 1px solid $borderColor;
    padding: 0px;
    position: relative;
  }
  &__leftArrow,
  &__rightArrow {
    position: absolute;
    top: 40%;
    height: 40px;
    width: 40px;
    border: 1px solid $borderColor;
    border-right: none;
    z-index: 1;
    @include flexPosition(center, center, false);
    cursor: pointer;
    &::after {
      content: "\f054";
      font-family: $FontAwesome;
      display: block;
      font-weight: $fontWeight600;
      @include font-size($largeHeaderText);
      color: $mediumDarkTextColor;
    }
  }
  &__leftArrow {
    transform: rotate(180deg);
  }
  &__rightArrow {
    right: 0px;
  }
  &__header {
    padding: 50px 0px 0px 50px;
    width: 50%;

    &--title {
      color: $darkHeaderTextColor;
    }
    &--subTitle {
      color: $headerTextRed;
      @include font-size($smalTextSize);
      font-weight: $fontWeight600;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  }

  &__container {
    width: 100%;
    @include flexPosition(center, center, false);
    flex-wrap: wrap;
  }
  &__button {
    position: absolute;
    cursor: pointer;
    &::before {
      display: none;
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 3px;
      z-index: 1;
    }
    &::after {
      content: "\f055";
      font-family: $FontAwesome;
      font-weight: $fontWeight600;
      font-size: 24px;
      color: $headerTextRed;
      z-index: 2;
      position: relative;
      box-shadow: 0 0 0 $headerTextRed;
      border-radius: 50%;
      animation: pulse 2s infinite;
    }
  }
  &__wrapper {
    width: 980px;
    position: relative;
    height: auto;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slider {
    width: 100%;
    display: flex;
    border-top: 1px solid $borderColor;
    &--wrapper {
      flex-basis: 16.66666%;
      cursor: pointer;
      overflow: hidden;
      &:not(:last-child) {
        border-right: 1px solid $borderColor;
      }
    }

    &--image {
      transition: transform 250ms ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    &--active {
      border: 1px solid $headerTextRed;
      border-right: 1px solid $headerTextRed !important;
      .hotSpots__slider--image {
        transform: scale(1.1);
      }
    }
  }

  &__modal {
    top: -164px;
    height: 377px;
    width: 300px;
    left: 44px;
    position: absolute;
    background: #fff;
    z-index: 3;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 45%;
      left: -12px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid $headerTextRed;
    }

    &--container {
      padding: 30px 30px 0px 30px;
    }

    &--title {
      color: $headerTextRed;
      text-transform: uppercase;
      @include font-size($smalTextSize);
      margin-bottom: 15px;
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      height: 16px;
      overflow: hidden;
    }
    &--description {
      height: 245px;
      overflow: hidden;
    }

    &--bottom {
      padding: 5px 12px;
      width: 100%;
      border-bottom: 1px solid $borderColor;
      @include flexPosition(flex-end, unset, false);

      a {
        @include font-size($mediumSmalTextSize);
        text-transform: uppercase;
        font-weight: $fontWeight700;
        letter-spacing: 1.5px;
      }
    }

    &--items {
      @include flexPosition(center, unset, false);
      width: 100%;
      padding: 8px 0px;
    }

    &--item {
      margin-right: 10px;
    }

    &--active {
      color: $headerTextRed;
    }
  }

  &__close {
    position: absolute;
    right: 0px;
    top: 23px;
    width: 25px;
    height: 25px;
    z-index: 5;
    &--1,
    &--2 {
      height: 25px;
      width: 2px;
      background-color: $headerTextRed;
    }
    &--1 {
      transform: rotate(45deg);
    }
    &--2 {
      transform: rotate(90deg);
    }
  }

  @media only screen and (max-width: 1200px) {
    &__wrapper {
      width: 100%;
    }
    &__rightArrow {
      display: none;
    }
    &__leftArrow {
    }
  }
  @media only screen and (max-width: 981px) {
    &__rightArrow {
      display: none;
    }
    &__leftArrow {
      height: 25px;
      width: 25px;
      &::after {
        @include font-size($mediumTextSize);
      }
    }
    &__header {
      padding: 25px 0px 0px 25px;
      h2,
      h3 {
        margin: 0px;
      }
      h2 {
        font-weight: 500;
      }
      h3 {
        @include font-size($mediumSmalTextSize);
      }
    }
    &__button {
      &::after {
        font-size: 22px;
      }
    }
    &__modal {
      position: fixed;
      top: 40%;
      left: 33%;

      &::before {
        display: none;
      }
    }

    &__slider {
      overflow-x: auto;
      &--wrapper {
        flex-basis: 33.33333%;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    &__header {
      width: 100%;
    }

    &__button {
      &::before {
        top: 4px;
        left: 2px;
        height: 14px;
        width: 14px;
      }
      &::after {
        font-size: 16px;
      }
    }

    &__modal {
      position: fixed;
      top: 15%;
      left: 10%;

      &::before {
        display: none;
      }
    }
  }
}
