.Imagewithtext {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .red__border {
    border: 1px solid #d1291c;
    border-right: none;
    padding: 0px 100px 0px 70px;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 450px;
  }
  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    margin: -7px;
  }

  &__image {
    @include flexPosition(unset, center, false);
    position: relative;
    padding: 0px !important;
  }

  &-text-container {
    @include flexPosition(unset, unset, true);
    padding: 0px 150px 0px 0px;

    .Imagewithtext-text {
      padding-top: 10px !important;
      h3,
      h5 {
        line-height: 32px !important;
        font-size: $largeHeaderText !important;
        margin-bottom: 0px !important;
        font-weight: 500;
      }
      h5 {
        font-size: $smalHeaderText !important;
        @include margin-top(20px);
      }
      h2 {
        font-size: $smalHeaderText !important;
        margin: 13px 0px !important;
      }
      p {
        color: $darkParagraphText !important;
        a {
          margin: 0px;
        }
      }
    }
    .Imagewithtext-subtitle {
      h3 {
        text-transform: uppercase;
        color: $headerTextRed;
        font-weight: $fontWeight600;
        letter-spacing: 2px;
        @include font-size($smalTextSize);
        margin: 0px;
      }
    }
    .large-text {
      h3 {
        @include font-size($mediumHeaderText);
        text-transform: none;
      }
    }
    .Imagewithtext-title {
      h2 {
        margin-top: 10px !important;
        color: $darkHeaderTextColor;
        letter-spacing: 0;
        font-weight: $fontWeight400;
        margin: 0px;
      }
    }
  }
  .flex-md-row-reverse {
    .red__border {
      border: 1px solid #d1291c;
      border-left: none;
      padding: 0px 70px 0px 100px;
    }

    .Imagewithtext-text-container {
      padding: 0px 0px 0px 150px;
    }
  }

  .embed-container {
    --video--width: 1296;
    --video--height: 540;

    position: relative;
    padding-bottom: calc(
      var(--video--height) / var(--video--width) * 100%
    ); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
    background: black;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .rich__text {
    .text-with-red-arrow-right{
      float: right;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .red__border {
    border: 0px !important;
    padding: 0px 15px !important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 1278px) {
  .Imagewithtext {
    &-text-container {
      padding: 0px 15px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Imagewithtext {
    &-text-container {
      margin-top: 20px !important;
    }
    .red__border {
      border: 0px !important;
      padding: 0px !important;
    }
    &-text-container {
      padding: 0px !important;
    }
    iframe {
      margin: 0;
    }

    &-text-container {
      order: 2;
    }
    &__image {
      order: 1;
    }
    .rich__text__button {
      margin: 0px;
    }
    img {
      max-height: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .Imagewithtext {
    &__image {
      padding: 0px !important;
    }
    iframe {
      width: 100%;
      height: 100%;
      min-height: 215px;
      margin-block-end: 1rem;
    }
  }
}
