.redlinecolumnseparator {
  &__wrapper {
    @include flexPosition(space-between, center, false);
  }
  &__container {
    h2 {
      color: $blackColor;
    }
    width: 20%;
    padding: 10px;
    &:first-child {
      width: 22%;
    }
    &:nth-child(3) {
      width: 35%;
      padding: 24px;
    }
    &:last-child {
      text-align: end;
    }
  }

  &__line {
    width: 10%;
    height: 2px;
    &::before {
      display: block;
      content: "";
      border-top: 2px solid #d1291c;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1025px) {
  .redlinecolumnseparator {
    &__wrapper {
      flex-direction: column;
    }
    &__container {
      text-align: center;
      padding: 0px !important;
      width: 100% !important;
      &:last-child {
        text-align: center !important;
      }
      h2{
        margin: 0;
      }
    }

    &__line {
      width: 20%;
      @include padding-bottom(40px);
      @include padding-top(40px);
    }
  }
}
