.error {
  background-image: url("../assets/images/dark.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  background-color: rgba(13, 13, 13, 0.8);
  width: 100%;
  text-align: center;
  margin-bottom: 0px !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 13, 13, 0.8);
    background: linear-gradient(
        209.28deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 65.61%,
        rgba(0, 0, 0, 0.9) 99.95%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      linear-gradient(
        180.33deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 65.61%,
        rgba(0, 0, 0, 0.9) 99.95%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.5) 100%
      );
  }
  &__container {
    @include padding-bottom(700px);
  }
  .container,
  .row {
    height: 100%;
    z-index: 1;
    position: relative;
  }

  &__title {
    @include padding-top(128px);
    h6 {
      color: $whiteTextColor;
      @include font-size($smalHeaderText);
      font-weight: $fontWeight600;
      letter-spacing: 2px;
      line-height: 18px;
    }
  }
  &__status {
    @include padding-top(40px);
    @include padding-bottom(40px);
    h3 {
      color: $headerTextRed;
      @include font-size(200px);
      font-weight: $fontWeight900;
      letter-spacing: 0;
      line-height: 200px;
      margin: 0;
    }
  }
  &__text {
    p {
      color: $whiteTextColor;
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 27px;
    }
  }

  &__links {
    @include padding-top(15px);
    @include padding-bottom(40px);
    ul {
      list-style-type: none;
      @include flexPosition(space-between, unset, false);
      flex-wrap: wrap;
      li {
        &:not(:last-child) {
          &::after {
            color: $whiteTextColor;
            content: "|";
            padding: 0px 5px;
          }
        }
        a {
          color: $whiteTextColor;
          @include font-size($smalHeaderText);
          font-weight: $fontWeight300;
          letter-spacing: 0;
          line-height: 27px;
        }
      }
    }
  }
  &__search {
    position: relative;

    input {
      height: 55%;
      width: 100%;
      font-size: 15px;
      @include font-size($mediumTextSize);
      padding: 10px 10px 10px 10px;
      letter-spacing: 0;
      line-height: 22px;
      border: none;
      outline: none;
      color: $lightHeaderColor;
    }

    &::after {
      z-index: 1000;
      content: "\f002";
      position: absolute;
      right: 15px;
      top: 7px;
      font-family: "Font Awesome\ 5 Free";
      display: block;
      font-weight: $fontWeight600;
      @include font-size($smalHeaderText);
      color: $headerTextRed;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;

    .row {
      align-items: center;
    }
  }
}

@media (max-width: 600px) {
  .error {
    height: 100%;
    &__links {
      ul {
        justify-content: center;
      }
    }
    &__status {
      h3 {
        line-height: normal;
      }
    }
    &__search {
      @include flexPosition(center, unset, false);
      input {
        width: 80%;
      }
      &::after {
        right: 55px;
      }
    }
  }
}
