.shortFacts4Col {
  padding: 0px !important;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $borderColor;
  &__col {
    padding: 30px 0px !important;
    border-right: 1px solid $borderColor;
    &:nth-child(4) {
      border-right: none;
    }
  }
  div {
    text-align: center;
  }
  &__amount {
    color: $darkHeaderTextColor;
    @include font-size($extraBigHeaderText);
    letter-spacing: 0;
    line-height: 58px;
  }
  &__text {
    color: $headerTextRed;
    @include font-size($smalTextSize);
    font-weight: $fontWeight600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 16px;
  }
  &__adjust{
    border: none;

    .shortFacts4Col__col{
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      border-right: 1px solid $borderColor;
      &:nth-child(1) {
        border-left: 1px solid $borderColor;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .shortFacts4Col {
    .container {
      max-width: 100%;
    }
    &__col {
      padding: 30px 10px !important;
      &:nth-child(2) {
        border-right: none;
      }
      border-bottom: 1px solid $borderColor;
    }
  }
}
