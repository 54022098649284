.story-imagetext {
  &__wrapper {
    position: relative;
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      border: 1px solid $borderColor;
      width: 315px;
      left: 0px;
      top: 50%;
    }
    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      border: 1px solid $borderColor;
      width: 315px;
      right: 0px;
      top: 50%;
    }
  }

  &__container {
    padding: 30px;
    border: 1px solid $borderColor;
  }
  &__image {
    img {
      max-width: 100%;
    }
  }
  &__text {
    @include flexPosition(space-between, unset, true);

    p {
      color: $darkHeaderTextColor;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
    }

    &-bottom {
      @include flexPosition(space-between, center, false);
      flex-wrap: wrap;
      h3 {
        color: $headerTextRed;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
        line-height: 27px;
      }
      p {
        padding-top: 5px;
        color: $darkParagraphText;
        @include font-size($mediumTextSize);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 22px;
      }
      a {
        display: inherit;
      }
    }
  }
}

@media (max-width: 1399px) {
  .story-imagetext {
    &__wrapper {
      &::before {
        display: none !important;
      }
      &::after {
        display: none !important;
      }
    }

    &__text {
      &-bottom {
        p {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .story-imagetext {
    &__container {
      padding: 0px;
      border: none;
    }
    &__image {
      text-align: center;
    }
    &__text {
      @include margin-top(20px);

      &-bottom {
        .btn-container {
          @include margin-top(20px);
        }
      }
    }
  }
}
@media (max-width: 1399px) {
  .story-imagetext {
    &__text {
      @include margin-top(20px);
    }
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
}
