$dropdownWidth : 365px;

.nolatoNavigation {
    position: sticky;
    top: 40px;
    z-index: 1040;
    padding: 8px 15px;
    background-color: #fff;

    @media (max-width: 1400px) {
        position: fixed;
        width: 100%;
    }

    & button:focus {
        outline: none;
    }

    & button:focus-visible {
        outline: 3px solid black;
    }

    & .activeNavItem {
        color: #d1291c !important;
    }

    &-scrollbar {
        margin-left: calc(100vw - 100%);
    }

    &__siteopacity {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: ease .2s all;
        
        &--show {
            z-index: 1001;
            backdrop-filter: blur(5px);
            background-color: #1c1c1c80;
            visibility: visible;
            pointer-events: all;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #000;

        @media (max-width: 1400px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
            
        @media (max-width: $breakpoint-tablets) {
            padding: 0 4px;
        }
    }

    &__toolbar {
        font-size: 12px;
        text-transform: uppercase;
        background-color: #382e2e;
        color: #fff;
        border-bottom: 3px solid $lightRedBackgroundColor;
        height: 40px;
        padding: 0 5px;
        position: sticky;
        top: 0;
        z-index: 1039;

        & .menubutton__hero {
            height: auto;
            -webkit-animation: nolatoFadeIn 2s;
            animation: nolatoFadeIn 2s;

            & a {
                
                background-color: #d1291c;
                padding: 6px 10px;
                color: #fff!important;   
            }
        }

        @media (max-width: 1400px) {
            
            position: fixed;
            width: 100%;
        }
        &-container {
            display: flex;
            align-items: center;   
            height: 100%;           

            & .fullHeight {
                height: 100%;
            }
        }
        &-stock, &-items {
            
            height: 100%;
            font-weight: 500;

            & span {
                padding: 0 20px;
                border-right: 1px solid #3C3C3C;
                height: 100%;
                display: inline-flex;
                align-items: center;
                color:#fff;

                & span {
                    border: unset;
                    padding: 0 4px 0 0;
                }
            }
        }
        &-stock {
            flex: 1;

            & span:first-of-type {
                position: relative;
                padding-left: 20px;                

                &.decrease {
                    &::before {
                    content:'';
                    left: 0;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background-image: $whiteArrow;
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    background-size: 16px;
                    transform: rotate(45deg);
                    }
                }

                &.increase {
                    &::before {
                    content:'';
                    position: absolute;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    background-image: $whiteArrow;
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    background-size: 16px;
                    transform: rotate(-45deg);                    
                    }
                }
            }
        }

        &-items {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            gap: 20px;

            & a {
                text-decoration: none !important;
                color: #fff !important;
            }

            & li:last-of-type {
                padding-right: 0;
                border-right: 0px solid #3C3C3C;                   
            }

            @media (max-width: $breakpoint-tablets) {
                display: none;
            }
    
        }

        &-stockvalue {
            color: #84CC61;
        }
    }

    &__logo {
        margin-right: 50px;

        @media (max-width: $breakpoint-tablets) {
            flex:1;
            margin-right: 0px;
        }

        & img {
            max-height: 50px;
            width: 98px;           
        }
    }

    &__searchField {            
        display: none;
        width: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        background-color: #fff;
        position: relative;

        &-closeButton {
            z-index: 1;
            width: 42px;
            height: 100%;
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 0px;
            background-image: $closeRed;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            transform: translateY(-50%);
            cursor: pointer;
        }

        & form {
            width: 100%;
        }

        & input {
            background-image: $searchRed !important; 
            background-position: 15px center;
            background-repeat: no-repeat;
            width: 100%;
            height: 48px;
            border-radius: 4px;
            outline: none;
            padding: 10px 50px;
            border: 1px solid #D3D3D3;
            font-size: 14px;            
        }
        
        &--visible {
            flex: 1;
            opacity: 1;
            pointer-events: all;
            height: 100%;
            height: 72px;
            display: flex;
            align-items: center;
            margin-right: 10px;

            @media (max-width: $breakpoint-tablets) {
                position: absolute;
                inset: 10px 15px;
                width: calc(100% - 30px);
                z-index: 2;        
                height: initial;
                align-items: center;
            }
        }
    }

    &__menu {

        @media (max-width: $breakpoint-tablets) {
            gap: 0px;
            display: none;
        }

        position: relative;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        flex: 1;
        height: 72px;

        &-hidden {
            width: 0;
            height: 0;
            flex: unset;
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        &-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding-right: 15px;
            height: 100%;
        }
        &-mobileTitle {
            display: none;

            @media (max-width: $breakpoint-tablets) {
                display: block;
                margin-right: 45px;
            }
        }

        &-mobileClose {
            display: none;

            @media (max-width: $breakpoint-tablets) {
                display: block;
                position: absolute;
                top: 10px;
                right: 23px;
                width: 40px;
                height: 40px;
                border-radius: 50% !important;
                background-image: $closeBlack;
                background-repeat: no-repeat;
                background-position: center;
                background-color: #fff;
                background-size: 16px;
                transition: ease .8s background-color;
                border: 0;
                cursor: pointer;
                
                &:hover {
                    background-color: $hoverGrey !important; 
                }                
            }
        }

        &-close {
            position: absolute;
            top: 25px;
            right: 22px;
            width: 40px;
            height: 40px;
            border-radius: 50% !important;
            background-image: $closeBlack;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            background-size: 16px;
            transition: ease .8s background-color;
            border: 0;
            cursor: pointer;
            pointer-events: all;
            z-index: 1;

            @media (max-width: $breakpoint-tablets) {
                background-image: $backBlack;                
                transform: rotate(90deg);
                top: 12px;
            }

            &:hover, &:focus-visible {
                background-color: $hoverGrey !important;
                text-decoration: none !important;
            }
        }
        &-back {
            position: absolute;
            top: 25px;
            left: 17px;
            width: 40px;
            height: 40px;
            border-radius: 50% !important;
            background-image: $backBlack;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            transition: ease .8s background-color;
            cursor: pointer;
            pointer-events: all;
            z-index: 2;
            display: none;
            
            @media (max-width: $breakpoint-tablets) {
                display: block;
                top: 12px;
                right: 22px;
                left: unset;
                transform: rotate(90deg);
            }

            &:hover {
                background-color: $hoverGrey !important;
                text-decoration: none !important;
            }
        }
        &-h1 {
            font-size: 1.375rem;
            letter-spacing: -0.52px;
            margin-bottom: 15px;
            width: fit-content;
            flex: unset !important;
            margin-right: 50px;
            padding: 0 !important;
        }
        &-h2 {
            font-size: 1.375rem !important;
            letter-spacing: -.52px;
            border-bottom: 0px solid #fff !important;
            padding: 6px 0 !important;
        }
        &-linebreak {
            height: 30px;
            min-height: 30px;
        }
        &-preamble {
            font-size: 16px;
            letter-spacing: -0.28px;
            color: $lightRedBackgroundColor;
            margin: 6px 0;
        }
        &-description {           
            letter-spacing: -0.24px;
            color: #7B7B7B;           
            font-size: 14px;
        }
        &-gridLink {  
            display: flex;         
            letter-spacing: -0.24px;
            color: #7B7B7B;           
            font-size: 14px;
            margin: 15px 0 0 0;
            background-image: $gridRed;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;

            & a {
                padding: 8px 0px !important;

                &:hover {
                    background-color: unset !important;
                }   
            }
        }
        &-listItem {
            
            display: flex;
            justify-items: center;
            align-items: center;
            padding: 16px 0;
            border-bottom: 1px solid $hoverGrey;
            font-size: 15px;
            cursor: initial;
            
            & a:hover {
                color: $lightRedBackgroundColor !important;
            }

            & span {
                padding: 0 !important;                
            }

            &--link {
                display: flex;
                flex: 1;                
            }

            &--children {
                width: 24px;
                height: 24px;
                background-color: $hoverGrey !important;
                background-image: $chevronGray;
                background-repeat: no-repeat;
                background-position: center;                   
                border-radius: 4px !important; 
                padding: 0 !important;
                cursor: pointer;

                &:hover {
                    background-color: $lightRedBackgroundColor !important;
                    background-image: $chevronWhite;
                }

                &--active {
                    background-image: $chevronWhite;
                    background-color: $lightRedBackgroundColor !important;
                }
            }   
        }

        &--item {

            @media (max-width: $breakpoint-tablets) {
                border-bottom: 1px solid $hoverGrey;
                padding: 16px 0 !important;
                position: initial;
                width: 100%;
            }

            display: flex;
            align-items: center;
            letter-spacing: 0.32px;
            font-size: 15px;

            &-extraOptions {
                display: none;
                
                @media (max-width: $breakpoint-tablets) {
                    display: flex;
                }                
            }
            & button {
                border: none;     
                background-color: unset;  
                //margin-left: 4px;
            }
            & .nameHolder {
                @media (max-width: $breakpoint-tablets) {
                    padding: 0;
                    flex: 1;
                }
            }

            & a {
                position: relative;                

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $redBorderColor;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: right;
                    transform-origin: right;
                    -webkit-transition: -webkit-transform .25s ease-in;
                    transition: -webkit-transform .25s ease-in;
                    transition: transform .25s ease-in;
                    transition: transform .25s ease-in,-webkit-transform .25s ease-in;
                }

                &:hover {                    
                    color: $redBorderColor;
                    
                    &::after {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                        -webkit-transform-origin: left;
                        transform-origin: left;
                    }   
                }
            }
            & a, button, .nameHolder {                
                text-decoration: none !important;
                color: #000;
                transition: ease .3s all;

                @media (max-width: $breakpoint-tablets) {
                    padding: 0px;
                }

                &:hover, &:focus-visible {
                    text-decoration: underline;
                    color: #000;

                    @media (max-width: $breakpoint-tablets) {
                        text-decoration: none;
                        background-color: #fff;
                    }
                }
            }
            & button:hover {
                background-color: $hoverGrey;
            }

            
            & .topNav {
                position: relative;
                
                &__children {
                    width: auto;
                    padding-right: 20px !important;

                    @media (max-width: $breakpoint-tablets) {
                        width: 100%;
                        text-align: left;                    
                        background-color: unset !important;

                        &::before {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: -1px;
                            background: $hoverGrey;
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            transition: ease .2s background-color;
                        }

                        &:hover::before {
                            background-color: $lightRedBackgroundColor !important;
                        }
                    }
    
                    &::after {
                        position: absolute;                        
                        inset: 0;
                        background-position: right center;
                        background-image: $chevronBlack !important;

                        @media (max-width: $breakpoint-tablets) {
                            transform: translateX(-7.4px);
                        }
                    }

                    &.activeDropdown {
                        color: #d1291c !important;
                
                        &::after {
                            background-image: $chevronRed !important;
                        }
                    }
                }

                &__item {
                    &::after {
                        content: unset;
                    }
                }

                &:hover {
                    color: $redBackgroundColor;                    
                    background-color: unset !important;

                    &::after {
                        background-image: $chevronRed !important;

                        @media (max-width: $breakpoint-tablets) {
                            background-image: $chevronWhite !important;
                        }
                    }
                }
            }

            &-toggler {
                display: inline-block;                
                width: 24px;
                height: 24px;
                right: 0;                
                border-radius: 4px;
                transition: ease .4s background-color;             
                cursor: pointer;   
                padding: 0 !important;
                
                &::after {
                    content: '';
                    background-image: $chevronBlack;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: ease .5s all;
                    transform: rotateX(0);

                    @media (max-width: $breakpoint-tablets) {
                        background-image: $plusGray;
                    }
                }

                @media (max-width: $breakpoint-tablets) {
                    margin-right: 0px;
                    width: 24px;
                    height: 24px;
                    background-color: $hoverGrey !important;
                    background-repeat: no-repeat;
                    background-position: center;                   
                    border-radius: 4px !important; 
                    padding: 0 !important;
                    cursor: pointer;

                    &:hover {
                        background-color: $lightRedBackgroundColor !important;

                        &::after {
                            background-image: $plusWhite !important;
                        }
                    }
                }

                &--active {                    
                    background-color: $lightRedBackgroundColor;                    

                    &::after {                        
                        background-image: $chevronWhite;                
                        transform: rotateX(180deg);

                        @media (max-width: $breakpoint-tablets) {
                            background-image: $minusWhite;
                            transform: unset;
                        }
                    }
                }                
            }

        }
    }

    &__dropdown {
        position: absolute;
        top: calc(100% - 2px);
        left: -162px;
        padding: 30px 15px 30px 30px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 3px 30px #00000026;
        display: flex;
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        width: $dropdownWidth;
        max-height: 70vh;
        text-align: left;
        visibility: hidden;
        transition: ease .3s all;
        cursor: initial;

        .nolatoNavigation__menu-listItem--children {
            transform: rotate(-90deg);

            @media (max-width: $breakpoint-tablets) {
                transform: initial;
            }
        }

        @media (max-width: $breakpoint-tablets) {
            z-index: 1;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            transform: unset;
            max-height: initial;
            border-radius: 10px 10px 0 0;
            padding: 15px 15px 15px 30px;
            //height: calc(100% + 60px);
            display: none;
        }

        &--show {
            opacity: 1;
            pointer-events: all;
            visibility: visible;
            display: flex;
        }
    }

    &__dropout {
        position: absolute;
        top: 0;
        left: 73%;
        padding: 30px 15px 30px 30px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 3px 30px #00000026;
        display: flex;
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        width: $dropdownWidth;
        max-height: 70vh;
        text-align: left;
        visibility: hidden;
        transition: ease .3s all;
        cursor: initial;
        z-index: 1;        
        //white-space: nowrap;
        @media (max-width: $breakpoint-tablets) {
            z-index: 1;
            top: 60px;
            left: 0;
            right: 0;
            width: 100%;
            transform: unset;
            max-height: initial;
            border-radius: 10px 10px 0 0;   
            padding: 15px 15px 15px 30px;
        }
        &--show {
            opacity: 1;
            pointer-events: all;
            visibility: visible;
            left: calc(100% - 20px);

            @media (max-width: 1630px) {
                width: 308px;
            }
            @media (max-width: 1350px) {
                width: 243px;
            }

            @media (max-width: $breakpoint-tablets) {
                left: 0;
                bottom: 0;
                width: 100%;
            }
        }
    }

    &__search {
        display: flex;
        align-items: center;
        width: 54px;
        height: 54px;       
        border: 2px solid #fff;
        border-radius: 8px;
        transition: ease .6s all;
        background: #fff;
        
        @media (max-width: $breakpoint-cellphone) {
            top: initial;
            z-index: 1;
            transition: initial;
        }
       

        &-holder {
            position: relative;
            visibility: visible;
            width: 50px;
            
            @media (max-width: $breakpoint-tablets) {
                order: 60;
            }

            &--hidden {
                width: 0;
                overflow: hidden;
                visibility: hidden;
                margin-right: -20px;
            }
        }

        &--icon {
            width: 48px;
            height: 48px;
            border-radius: 4px;
            background-image: $searchBlack;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;            
            cursor: pointer;
            overflow: hidden;

            transition: ease .4s all;
            border: 1px solid #D3D3D3;

            &--active {
                background-color: $hoverGrey;
                background-image: $searchBlack;
            }

            &:hover, &:focus-visible {
                background-color: $hoverGrey;
                background-image: $searchBlack;
                border: 1px solid #fff;
            }    
        }        
    }

    &__languageSelector {

        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width: $breakpoint-tablets) {
            order: 50;
        }

        &:hover, &:focus-visible {
            & .nolatoNavigation__languageSelector--toggler {
                background-color: $hoverGrey;
            }
        }

        &--toggler {
            display: inline-block;                
            width: 30px;
            height: 34px;
            position: absolute;
            right: 6px;                
            border-radius: 4px;
            transition: ease .4s background-color;             
            cursor: pointer;   
            padding: 0 !important;

            @media (max-width: $breakpoint-tablets) {
                display: none;
            }                        

            &::after {
                content: '';
                background-image: $chevronBlack;
                display: flex;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                transition: ease .5s all;
                transform: rotateX(0);
            }

            &-active {                    
                background-color: $lightRedBackgroundColor !important;                    
                
                &::after {                        
                    background-image: $chevronWhite;                
                    transform: rotateX(180deg);
                }
            }        
            
            &:hover, &:focus-visible {                
                background-color: $hoverGrey;
            }
            
        }

        &--box {
            position: relative;
            display: flex;
            align-items: center;            
            border: 1px solid #D3D3D3;
            border-radius: 4px;
            width: 48px;
            height: 48px;
            justify-content: center;            

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: #fff;   
                border-radius: 4px;
                border: none;
                background: #fff;

                & img {
                    vertical-align: middle;
                    border-style: none;
                    float: left;
                    width: 22px;
                    height: auto;
                    object-fit: contain;
                }

                @media (max-width: $breakpoint-tablets) {
                    margin-left: 0;
                    width: 46px;
                }
            }

            &-language {
                display: flex;
                align-items: center;
                padding: 0 46px 0 16px;
                position: relative;                
                height: calc(100% - 2px);
                font-size: 14px;
                letter-spacing: .5px;
                cursor: pointer;
                background-color: #fff;
                border: unset;
                margin-right: 2px;
                border-radius: 8px;

                & img {
                    vertical-align: middle;
                    border-style: none;
                    float: left;
                    width: 22px;
                    height: auto;
                    object-fit: contain;
                    margin-right: 10px;
                }

                @media (max-width: $breakpoint-tablets) {
                    display: none;
                }

                &-headingBox {
                    display: none;
                    pointer-events: none;

                    @media (max-width: $breakpoint-cellphone) {
                        display: flex;

                        &:first-child {
                            flex: 1;
                        }    
                        & button {
                            pointer-events: all !important;
                        }
                    }
                }
                &--show {
                    opacity: 1 !important;
                    visibility: visible !important;
                    pointer-events: all !important;
                }
                &--available {
                    position: absolute;
                    top: calc(100% + 12px);
                    background: #fff;
                    border: 1px solid #D3D3D3;
                    right: -1px;
                    padding: 6px 12px;
                    border-radius: 4px;
                    box-shadow: 0 3px 30px #00000026;
                    opacity: 0;
                    transition: ease .6s opacity;
                    visibility: hidden;
                    pointer-events: none;
                    white-space: nowrap;

                    & img {
                        vertical-align: middle;
                        border-style: none;
                        float: left;
                        width: 22px;
                        height: auto;
                        object-fit: contain;
                    }

                    & a {
                        display: flex;
                        color: #000;
                        text-decoration: none;                        
                        padding: 10px 0;

                        &:not(:last-child)
                        {
                            border-bottom: 1px solid $hoverGrey;
                        }

                        &:hover, &:focus-visible {
                            color: $lightRedBackgroundColor;
                        }
                    }
                }
            }
        }
    }

    &__burgerMenu {
        display: none;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        width: 48px;
        height: 48px;
        border: 1px solid #D3D3D3;
        background-color: #fff;

        & span {
            display: inline-block;
            width: 24px;
            height: 2px;
            position: absolute;
            background-color: #000;            
            transition: ease .3s all;
            opacity: 1;
            border-radius: 4px;
            left: 50%;
            transform: translateX(-50%);

            &:nth-child(1) {
                top: 16px;
            }
            &:nth-child(2) {
                top: 22px;
            }
            &:nth-child(3) {
                top: 28px;
            }
        }

        &--active {
            & span {
                display: inline-block;
                height: 2px;
                position: absolute;
                background-color: #000;
    
                &:nth-child(1) {          
                    transform: rotate(-45deg)translate(-13px, -4px);                              
                }
                &:nth-child(2) {                    
                    transform: rotate(45deg)translate(-8px, 8px);
                }
                &:nth-child(3) {
                    opacity: 0;                    
                }
            }            
        }
        &--activeMain {

            @media (max-width: $breakpoint-tablets) {
                display: block;                
                background-color: #fff;                
                position: fixed;
                top: 0px;
                opacity: 1;
                text-align: left;
                border-radius: 10px 10px 0 0;
                flex-direction: row;
                padding: 15px 30px;
                transition: all .3s;
                z-index: 3000;
                left: 10%;
                right: 0;                
                box-shadow: 0px 3px 30px #00000026;
                bottom: 0;
                cursor: initial;
                height: auto;
                overflow-y: auto;
                overflow-x: hidden;      

                &:has(.nolatoNavigation__dropdown--show) {
                    overflow-y: hidden !important;
                }
            }
        }

        @media (max-width: $breakpoint-tablets) {
            display: block;
            order: 70;
        }
    }
}