.experienceEditorMode {
  .two-column {
    &__right {
      display: block !important;
    }
  }

  .headerselecttext {
    &__select-button {
      h2 {
        opacity: 1 !important;
      }
    }
    &__select-text {
      display: flex !important;
      margin-top: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid black;
      &__container {
        opacity: 1 !important;
      }
    }
  }
  .FAQ__collapse-container {
    display: block !important;
  }
}
div:not(.experienceEditorMode){
  .text-title,.shares-links{
    div:empty{
      display: none;
    }
  }
}
