.secondary-menu {
  //height: 60px;
  background: #f8f9fa;
  z-index: 1000;

  &__fixed {
    position: sticky;
    position: -webkit-sticky;
    top: 126px;
    display: block;
    animation: nolatoFadeIn 1s;
    height: 40px;
    margin-top: 0px !important;
  }
  .container {
    height: inherit;
    // position: relative;
  }

  &__toggleDropdown {
    padding: 0.75rem 1.5rem 0.75rem 0rem;
    display: none;
    outline: none;
    border: none;
    position: relative;
    background-color: transparent;
    font-weight: $fontWeight600;
    transition: color 0.5s ease;

    &:focus {
      outline: none !important;
    }

    &:after {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      display: block;
      font-weight: 600;
      font-size: 1rem;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      color: $SubmenuRed;
      transition: transform 0.5s ease;
    }

    &--active::after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
  &__links {
    list-style-type: none;
    // @include flexPosition(center, center, false);
    display: flex;
    align-items: center;
    height: 100%;
    // position: absolute;
    width: auto;
    // top: 0;
    // left: 0;
    // right: 0;
    margin: 0 auto 0 auto;

    li {
      @include flexPosition("unset", center, false);
      height: inherit;
      margin-bottom: -1px;
      @include font-size($textSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      transition: all 0.5s;

      &:first-child {
       & div {
          padding-left: 0;
       }
      }

      & div {
        border-right: 1px solid #ede9e8;
        padding: 10px 16px;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: $breakpoint-tablets) {
          width: 100%;
          border-right: none;
          padding-left: 0;
        }
      }
      cursor: pointer;
      a {
        text-decoration: none;
        color: black;
        border-bottom: 1px;
        transition: all 0.5s;
      }
      &:hover {
        a {
          color: $headerTextRed !important;
        }
        // border-bottom: 1px solid $headerTextRed;
        // z-index: 100
      }
    }
    &--active {
      a {
        color: $headerTextRed !important;
      }
      border-bottom: 1px solid $headerTextRed;
      z-index: 100;
    }
  }
  &__sub-menu {
    width: 40%;
    height: 70%;
    display: none;
    justify-content: space-between;
    &-active {
      display: flex;
    }
    li {
      @include flexPosition("unset", center, false);
      &:hover {
        a {
          color: $headerTextRed !important;
        }
      }
    }
    a {
      color: $darkParagraphText;
      @include font-size($mediumSmalTextSize);
      letter-spacing: 0;
      line-height: 16px;
    }
  }
  &__breadcrumbs {
    height: 100%;
    @include flexPosition(flex-end, center, false);
    li {
      @include flexPosition(unset, center, false);
      padding-right: 6px;
      @include font-size($mediumSmalTextSize !important);

      a {
        color: $darkParagraphText;
        span {
          &::after {
            padding-left: 6px;
          }
        }

        &:hover {
          color: $headerTextRed;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .secondary-menu {
    &__fixed {
      position: fixed;
      width: 100%;
    }
  }
}
@media (max-width: 1100px) {
  .secondary-menu {
    top: 110px;
  }
}

@media (max-width: 992px) {
  .secondary-menu {
    height: auto;

    &.hideOnMobile {
      display: none;
    }

    &__toggleDropdown {
      display: block;
    }

    &__links {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;

      li {
        color: #333;
        padding: 0.75rem 0;
        font-size: 1.125rem;
        font-weight: $fontWeight400;
        &:hover,
        a:focus {
          color: $SubmenuRed;
        }
      }

      li:not(:last-child) {
        border-bottom: 1px solid $borderColor;
      }
    }
    .menubutton__hero {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .secondary-menu {
    // border-top: 1px solid rgb(237, 233, 232);
    // position: fixed;
    // width: 100%;
    margin-top: 15px;
    box-shadow: 0 13px 17px 0 rgba(0, 0, 0, 0.1),
      inset 0px 25px 15px -11px rgba(0, 0, 0, 0.1);
  }
}
