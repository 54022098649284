.read-image-column {
  &__title {
    h2{
      @include margin-bottom(15px);
    }
    &-small {
      h2 {
        margin-top: 20px;
        @include font-size($largeHeaderText);
      }
    }
  }
  &__row {
    border: 1px solid $borderColor;
    margin-right: 0;
    margin-left: 0;
  }
  &__col {
    padding: 0px !important;
    &:nth-child(6n + 6) {
      .read-image-column__wrapper {
        border-right: none;
      }
    }
  }
  &__wrapper {
    padding: 15px;
    border-right: 1px solid $borderColor;
  }

  &__container {
    height: 300px;
    @include flexPosition(unset, unset, true);
    cursor: pointer;
    &:hover {
      p {
        color: $headerTextRed;
      }
    }

    &-image {
      height: 85%;
      width: 100%;
      position: relative;
      overflow: hidden;

      &-image {
        background-image: url("../assets/images/dark.jpg");
        width: 100%;
        height: 100%;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &-text {
      width: 100%;
      p {
        padding-top: 10px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
@media (max-width: 991px) {
  .read-image-column__container-image-image {
    background-position: center;
  }
  .read-image-column__container-text{
    text-align: center;
  }
}
@media (max-width: 575px) {
 
  .read-image-column {
    &__container {
     max-height: 280px;
    }
  }
}
