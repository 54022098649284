.shortFacts2Col {
  height: 100%;
  .container,
  .row {
    height: inherit;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 41%;
    height: 1px;
    background-color: #ede9e8;
    width: 118%;
    top: 50%;
    transform: translateX(-50%);
  }
  .container {
    margin: 0px;
    padding: 0px;
  }
  padding: 0px !important;
  display: flex;
  flex-wrap: wrap;

  &__col {
    padding: 30px 0px !important;
    width: 50%;
    @include flexPosition(center, unset, true);
    &:nth-child(2n) {
      border-right: none;
    }
  }
  div {
    text-align: center;
  }
  &__amount {
    color: $darkHeaderTextColor;
    @include font-size($extraBigHeaderText);
    letter-spacing: 0;
    line-height: 58px;
  }
  &__text {
    color: $headerTextRed;
    @include font-size($smalTextSize);
    font-weight: $fontWeight600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 769px) {
  .shortFacts2Col {
    &::before {
      display: none;
    }
    &__col {
      border-bottom: 1px solid $borderColor;
      &:nth-child(odd) {
        border-right: 1px solid $borderColor;
      }
    }
  }
}
