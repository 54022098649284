.card-text-button {
  &__container {
    border: 1px solid $borderColor;
    padding: 30px !important;
  }
  &__subtitle {
    span {
      color: $headerTextRed;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 0;
      line-height: 16px;
    }
  }

  &__title {
    padding-top: 10px;
    height: 75px;
    overflow: hidden;
    h4 {
      color: $darkHeaderTextColor;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  &__text {
    margin: 40px 0px;
    height: 130px;
    overflow: hidden;
  }
  &__button {
    text-align: center;
    padding-bottom: 10px;
  }

  &__pagination {
    margin: 40px 0px;
    position: relative;
    @include flexPosition(unset, center, false);
    &-container {
      @include flexPosition(unset, center, false);
      padding: 0px 60px;
    }
    &-left {
      cursor: pointer;
      &::before {
        content: "\f054";
        position: absolute;
        font-family: $FontAwesome;
        transform: rotate(180deg);
        display: block;
        padding-top: 2px;
        font-weight: $fontWeight600;
        padding-left: 10px;
        font-size: 16px;
        color: $headerTextRed;
        left: 10px;
        top: 2px;
      }
    }

    &-right {
      cursor: pointer;
      &::after {
        content: "\f054";
        position: absolute;
        font-family: $FontAwesome;
        @include flexPosition(unset, center, false);
        padding-top: 2px;
        font-weight: $fontWeight600;
        padding-left: 10px;
        font-size: 16px;
        color: $headerTextRed;
        right: 10px;
        top: 2px;
      }
    }

    &-item {
      width: 30px;
      height: 30px;
      @include flexPosition(center, center, false);
      transition: all 0.3s;

      &:not(:last-child) {
        margin-right: 15px;
      }
      cursor: pointer;
      a {
        display: block;
        color: $blackColor;
        @include font-size($mediumTextSize);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 24px;
      }

      &--active,
      &:hover {
        background-color: $headerTextRed;
        a {
          color: $whiteTextColor;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .card-text-button {
    &__container {
      padding: 30px 15px;
    }
    &__button {
      text-align: start;
    }
  }
}
