.view-card {
  width: 100%;
  @include flexPosition(flex-end, unset, false);
  flex-wrap: wrap;
  align-content: flex-start;

  a {
    width: 50%;
    min-width: 300px;
  }
  &__item {
    @include flexPosition(space-between, center, false);
    border: 1px solid $borderColor;
    padding: 18px 24px;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      .view-card__container-text {
        color: $headerTextRed;
      }
    }
  }

  &__container {
    &-text {
      color: $mediumDarkTextColor;
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      letter-spacing: 0;
    }
  }
  &__link {
    span {
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 700px) {
  .view-card {
    a {
      width: 100%;
    }
  }
}
