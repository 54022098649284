.bulletRow {
  &__container {
    height: auto;
    width: 100%;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &__title {
    h3 {
      @include font-size($smalTextSize);
      @include margin-bottom(15px);
    }
  }
  &__item {    
    width: calc(25% - 0.5rem);
    margin-right: 0.5rem;
    @include margin-top(12px);
    @include flexPosition(unset, start, false);
    position: relative;
    &-text {
      @include flexPosition(unset, center, false);
      flex-wrap: wrap;

      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
      margin-left: 14px;
    }
    &::before {
      content: "";
      margin-right: 8px;
      display: inline-table;
      background: $headerTextRed;
      height: 7px;
      width: 7px;
      margin-top: 7px;
      position: absolute;
      top: 2px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .bulletRow {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 769px) {
  .bulletRow {
    &__item {
      width: 50%;
    }
  }
}
@media only screen and (max-width: 450px) {
  .bulletRow {
    margin: 40px 0px;
    &__items {
      flex-direction: column;
    }
    &__item {
      width: 100%;
    }
  }
}
