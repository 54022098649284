.tags {
  padding: 20px 0px 10px 0px;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    border-radius: 12px;
    background-color: #faf8f7;
    border: 1px solid #f2eded;
    padding: 0px 10px 2px 10px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    transition: all 0.2s;

    &:hover {
      border-color: $headerTextRed;
    }

    a {
      color: $darkParagraphText;
      font-size: 12px;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 0;
    }
  }
}
