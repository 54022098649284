.ir-links-image {
  &__container {
    height: 100%;
    width: 100%;

    &-image {
      width: 100%;
      max-height: 500px;
      height: auto;
    }
  }

  .report-news__items {
    &__item {
      height: auto;
      border: 1px solid $borderColor;
      padding: 20px 15px;
      border-bottom: none;
      min-height: 90px;
    }
    &:last-child {
      border-bottom: 1px solid $borderColor;
    }
  }
}

@media only screen and (max-width: 769px) {
  .ir-links-image {
    &__container {
      &-image {
        margin-top: 40px;
      }
    }
  }
}
