.imagewithcolumns {
  &__text-wrapper {
    height: 100%;
    @include flexPosition(space-between, unset, false);
    align-content: flex-start;
    flex-wrap: wrap;
  }

  &__text-container {
    width: 45%;
    @include margin-bottom(30px);
  }

  &__text-title {
    h4 {
      color: $headerTextRed;
      line-height: 27px;
    }
  }
  &__media-info {
    @include margin-top(20px);
    color: $darkParagraphText;
    @include font-size($mediumSmalTextSize);
    letter-spacing: 0;
    line-height: 16px;
  }

  iframe {
    width: 100%;
    height: 330px;
  }
}

@media only screen and (max-width: 991px) {
  .imagewithcolumns {
    &__text-container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .imagewithcolumns {
    .col-lg-7 {
      order: 2;
    }
    .col-lg-4 {
      order: 1;
    }
    &__text-wrapper {
      @include margin-top(40px);
    }
  }
}

@media only screen and (max-width: 576px) {
  .imagewithcolumns {
    iframe {
      height: 215px;
    }
  }
}
