.mfn-subscribe {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;

    li {
      margin-right: 0.5rem;
      input,
      label {
        cursor: pointer;
      }
    }
  }
  p {
    margin: 0.5rem 0;
    display: block;
  }

  label {
    color: $darkParagraphText;
    @include font-size($mediumSmalTextSize);
    font-weight: $fontWeight600;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .subscription-wrapper {
    input[type="text"],
    textarea,
    select {
      display: block;
      cursor: pointer;
      width: 100%;
      max-width: 400px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }

  button {
    @include btnColors(#fff, $headerTextRed, true);
    @include button;
    margin-top: 0.5rem;
    padding: 10px 30px 10px 30px;
    display: inline;

    @media (max-width: 450px) {
      margin: 0 !important;
      margin-top: 1rem !important;
      width: 100%;
    }
  }
}

.mfn-subscribe * {
  padding: 0;
  @include font-size($smalHeaderText);
  font-weight: $fontWeight300;
  color: $mediumDarkTextColor;
  line-height: 27px;
}
#policy-text{
  a{
    color: $headerTextRed !important;
  }
}