.signature-component {
  @include margin-top(50px);
  &__container {
    &:not(:first-child) {
      @include margin-top(25px);
    }
    &-title {
      color: $headerTextRed;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    &-text {
      color: $darkGreyColor;
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      a {
        color: $headerTextRed;
        text-decoration: underline;
        cursor: pointer;
        @include font-size($mediumTextSize);
      }
    }
    .btn-container {
      @include margin-top(25px);
    }
  }
}
