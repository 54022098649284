#wrapper > .nolato-component {
  @include margin-top(89px);
}
#wrapper > .nolato-component ~ .nolato-component {
  @include margin-top(0px);
}

.nolato-component {
  .nolato-component {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border: none;
  }

  border-bottom: 1px solid $borderColor;
  @include margin-bottom(89px);
  @include padding-bottom(89px);
  &__tiny {
    padding-bottom: 0px !important;
    @include margin-bottom(40px);
  }
  &__large {
    border-bottom: 1px solid $borderColor;
    @include padding-top(31px);
    @include margin-bottom(120px);
    @include padding-bottom(120px);
  }
  img {
    max-width: 100%;
    width: 100%;
  }

  &__no-space {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
  &__no-margin {
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    &__top {
      margin-top: 0px !important;
    }

    &__bottom {
      margin-bottom: 0px !important;
    }
  }

  &__no-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    &__top {
      padding-top: 0px !important;
    }

    &__bottom {
      padding-bottom: 0px !important;
    }
  }

  &__no-border {
    border: none !important;
  }
}
