.search-result {
  min-height: 400px;

  &__amount {
    padding-bottom: 15px;
    padding-left: 20px;

    &-text {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      letter-spacing: 0;
    }
  }
  &__item {
    padding: 20px;
    border-bottom: 1px solid $borderColor;
    display: flex;
    transition: all 0.2s ease-in;
    pointer-events: none;
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }

    &-title {
      color: $headerTextRed;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
    }
    &-text {
      color: $darkHeaderTextColor;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      padding-bottom: 25px;
    }
    &-breadcrumbs {
      display: flex;
      text-decoration: none;

      li {
        a {
          pointer-events: auto;
          color: $darkParagraphText;
          @include font-size($mediumSmalTextSize);
          letter-spacing: 0;
          z-index: 100;
          transition: 200ms;
          &:hover {
            color: $headerTextRed;
          }
        }
        &:not(:last-child):after {
          content: "\f054";
          font-family: $FontAwesome;
          display: inline;
          font-weight: $fontWeight600;
          padding: 0px 10px;
          font-size: 12px;
          color: $headerTextRed;
        }
      }
    }
  }

  &__container {
    width: 85%;
  }
  &__icon {
    width: 15%;
    @include flexPosition(flex-end, center, false);
    cursor: pointer;
    pointer-events: auto;
    &::after {
      content: "\f054";
      font-family: $FontAwesome;
      display: inline;
      font-weight: $fontWeight600;
      padding-left: 10px;
      font-size: 24px;
      color: $headerTextRed;
    }
  }

  @media only screen and (max-width: 500px) {
    &____item-breadcrumbs {
      flex-wrap: wrap;
    }

    &__container {
      width: auto;
    }

    &__item {
      padding: 20px 0px;
    }
  }
}
