.chips {
  padding: 20px 0px 10px 0px;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    border-radius: 12px;
    background-color: #f2eded;
    border: 1px solid #f2eded;
    padding: 0px 10px 2px 10px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    transition: all 0.2s;

    &:hover {
      span {
        color: $headerTextRed;
        &::before {
          color: $headerTextRed;
        }
      }
    }

    &-text {
      color: $darkParagraphText;
      font-size: 12px;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 0;

      &::before {
        content: "\f02b";
        font-family: $FontAwesome;
        display: inline-block;
        padding-top: 2px;
        font-weight: $fontWeight600;
        padding-right: 10px;
        font-size: 12px;
        color: $mediumDarkTextColor;
      }
    }
  }
}
