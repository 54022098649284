.btn-container {
  display: inline-block;
  transition: all 0.3s;
  position: relative;

  .btn {
    &-default {
      @include button;
      &-thin {
        padding: 10px 30px 10px 30px;
      }
      &-big {
        padding: 15px 90px;
        @include font-size($smalHeaderText);
      }
    }
    &-backgroundcolor-transparent {
      @include btnColors($headerTextRed, #fdf7f6, true);
    }

    &-backgroundcolor-red {
      @include btnColors(#fff, $headerTextRed, true);
    }
    &-backgroundcolor-white {
      @include btnColors($mediumDarkTextColor, $backGroundColorDropDown, false);
      border: 2px solid $backGroundColorDropDown;
      transition: transform 250ms ease-in !important;
    }
  }
}

.full-with {
  width: 100%;
  button {
    width: inherit;
  }
}

@media (max-width: 450px) {
  .btn-container {
    width: 100%;
    .btn-default {
      padding: 18px 0px !important;
    }
  }
  .btn {
    &-default {
      width: 100%;
    }
  }
}
