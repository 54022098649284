.board-directors {
  &__header {
    @include padding-bottom(50px);
  }
  div[class*="col"] {
    min-width: 230px;
    @include padding-bottom(50px);
  }
  .col-lg-10{
    padding-bottom: 0 !important;
  }
  &__image {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  &__title {
    padding: 10px 0px 20px 0px;
    border-bottom: 1px solid $borderColor;
    span {
      color: $darkParagraphText;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      line-height: 16px;
    }
    h4 {
      color: $headerTextRed;
      font-weight: $fontWeight300;
      letter-spacing: 0;
    }
    &-container {
      @include flexPosition(space-between, unset, false);
    }
    &--collapse {
      color: $headerTextRed !important;
      display: none;
      cursor: pointer;
      &::after {
        content: "+";
        font-size: 18px;
        padding-left: 5px;
        transition: all 0.3s ease-in;
      }
    }
    &--active {
      &::after {
        content: "-";
      }
    }
  }
  &__items {
    &-item {
      border-bottom: 1px solid $borderColor;
      padding: 12px 0px;
      &-title {
        text-transform: uppercase;
        color: $darkParagraphText;
        @include font-size($mediumSmalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 1.5px;
        line-height: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .board-directors {
    div[class*="col"] {
      @include padding-bottom(25px);
    }
    h3 {
      margin: 0px;
      width: 80%;
    }
    &__image {
      max-height: 200px;
      max-width: 160px;
      overflow: hidden;
    }

    &__title {
      border: none;
      &--collapse {
        display: flex;
      }
    }
    &__items {
      display: none;
      max-height: 1500px;
    }
  }
}

@media (max-width: 768px) {
  .board-directors {
    &__image {
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
    }

    &__title {
      border: none;
      &--collapse {
        display: flex;
      }
    }
    &__items {
      display: none;
      max-height: 1500px;
    }
  }
}

@media (max-width: 575px) {
  .board-directors {
    img {
      width: 50%;
    }
  }
}
