.contact-card {
  width: 100%;
  max-width: 450px;
  a {
    width: 100%;
  }
}
.contact-cardList {
  width: 100%;
  .contact-card__container,
  a {
    max-width: 450px;
    min-width: 440px;
  }
}
.contact-card,
.contact-cardList {
  &__title {
    width: 100%;
    margin-bottom: 16px;
    h3 {
      color: $darkParagraphText;
      font-size: $mediumTextSize;
      line-height: 18px;
      font-weight: $fontWeight600;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__container {
    width: 100%;
    display: flex;
    padding: 16px;
    border: 1px solid $borderColor;
    margin-bottom: 5px;
    transition: all 0.3s ease-in;

    &-pic {
      width: 110px;
      height: auto;
      overflow: hidden;
      position: relative;
      transform: translateZ(0);
    }
    &-img {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        border-radius: 50%;
      }
    }
    &-text {
      width: auto;
      max-width: 70%;
      display: block;
      padding-left: 25px;
      @include flexPosition(center, unset, true);

      &-name {
        color: $darkHeaderTextColor;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
        margin: 0px;
      }
      &-position {
        color: $headerTextRed;
        @include font-size($smalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 0;
      }

      &-contact {
        padding-top: 8px;
        a {
          margin: 0px;
          color: $darkParagraphText;
          @include font-size($smalTextSize);
          letter-spacing: 0;
          line-height: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-card {
    a {
      margin-right: 0px;
      width: 100%;
      min-width: 100%;
    }
  }
}

@media only screen and (max-width: 450px) {
  .contact-cardList {
    a {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    .contact-card__container {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
  .contact-card {
    width: 100%;
    &__container {
      align-items: center;
      &-pic {
        width: 100px;
        height: 100px;
      }
    }
  }
}
