.choose-language {
  background-color: $lightRedBackgroundColor;
  padding: 8px 0px;
  max-height: 50px;
  p,
  a {
    color: $whiteTextColor;
    @include font-size($smalTextSize);
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }

  &__wrapper {
    @include flexPosition(center, center, false);
    ul {
      display: flex;
      padding: 0px 10px;
    }
    li {
      margin-right: 10px;
      a {
        display: block;
        border: 1px solid #fff;
        overflow: hidden;
        transition: ease-in 200ms;
        height: 25px;
        width: 35px;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 565px) {
  .choose-language {
    max-height: unset;
    &__wrapper {
      flex-wrap: wrap;
      p {
        @include font-size($mediumSmalTextSize);
      }
      ul {
        margin-top: 5px;
      }
      li {
        margin-right: 20px;
      }
    }
  }
}
