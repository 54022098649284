.map {
  .rich__text {
    width: 100%;
  }
  .text-component {
    h2 {
      width: 100% !important;
    }
  }
  &__right {
    @include flexPosition(flex-end, unset, false);
    flex-wrap: wrap;
    @media only screen and (max-width: 567px) {
      justify-content: flex-start;
    }
    .text-with-red-arrow-right {
      margin: 10px 0px;
    }
  }
  &__link {
    text-transform: uppercase;
  }

  &__wrapper {
    width: 100%;
    height: 650px;
    border-radius: 25px;
    @include margin-top(30px);
    canvas {
      outline: none;
    }
  }
  &__marker {
    background-image: url("../assets/icons/mapMarker.svg");
    background-size: cover;
    width: 50px;
    height: 50px;
    cursor: pointer;
    filter: $svgRedColor;
    border-radius: 50%;
  }
  &__popup {
    width: 100%;
    &-container {
      padding: 16px;
    }
    &-location {
      &--country {
        color: $headerTextRed;
        font-size: 12px;
        @include font-size($mediumSmalTextSize);
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
      }
      &--company {
        margin-top: 10px;
        color: $darkHeaderTextColor;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
      }
    }
    &-text {
      margin-top: 10px;
      a {
        color: $headerTextRed !important;
      }
    }
    &-footer {
      padding: 15px 16px 15px;
      border-top: 1px solid $borderColor;
      @include flexPosition(space-between, unset, false);
      &--phone {
        color: #424242;
        @include font-size($mediumSmalTextSize);
        letter-spacing: 0;
        line-height: 18px;
      }
      &--link {
        color: $headerTextRed;
        text-transform: uppercase;
        @include font-size($verySmalTextSize);
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 12px;
        &:hover {
          color: $headerTextRed;
        }
      }
    }
  }
  .mapboxgl-popup-content {
    padding: 0px !important;
    border-radius: 2px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .mapboxgl-ctrl-bottom {
    &-left {
      display: none;
    }
    &-right {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    &__wrapper {
      height: 600px;
    }
  }
}
