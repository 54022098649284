.video-card {
  div[class*="col"] {
    border: 1px solid $borderColor;
  }
  &__wrapper {
    padding: 24px 9px;
    height: 540px;
    &__video {
      width: 100%;
      height: 230px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
    &__body {
      &-title {
        @include padding-top(24px);
        h3 {
          color: $headerTextRed;
          @include font-size($smalTextSize);
          font-weight: $fontWeight600;
          letter-spacing: 1.5px;
          line-height: 16px;
          text-transform: uppercase;
        }
      }
      &-name {
        h4 {
          color: $darkHeaderTextColor;
          @include font-size($largeHeaderText);
          letter-spacing: 0;
          line-height: 32px;
        }
      }
      &-text {
        height: 120px;
        overflow: hidden;
      }
      &-link {
        position: absolute;
        bottom: 16px;
        padding-bottom: 10px;
        right: 20px;
        span {
          text-transform: uppercase;
          @include font-size($smalTextSize);
          font-weight: $fontWeight600;
          letter-spacing: 1.5px;
          line-height: 16px;
          color: $headerTextRed;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .video-card {
    div[class*="col"] {
      padding: 0px;
      @include margin-top(30px);
      border: none;
    }
    &__wrapper {
      border: none;
      padding: 0px;
    }
    &__wrapper__body {
      padding: 0px 20px 20px 20px;

      &-text {
        height: auto;
      }
    }
  }
}
