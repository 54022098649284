.selection-buttons {
  @include margin-top(25px);
  &:last-child {
    @include padding-bottom(50px);
  }

  &__container {
    &-items {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      border: 1px solid $borderColor;
      border-radius: 18px;
      padding: 5px 20px;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;

      a {
        color: $darkParagraphText;
        @include font-size($mediumTextSize);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 22px;
      }

      &:hover,
      --active {
        border-color: $headerTextRed;
        a {
          color: $headerTextRed;
        }
      }
    }
  }
}
