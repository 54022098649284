.report-news,
.three-column {
  .col-lg-4 {
    height: 100%;
  }
  &__title {
    h2 {
      @include margin-bottom(15px);
    }
  }
  &__items {
    width: 100%;
  }
  &__sub-title {
    @include margin-bottom(16px);
    h4 {
      margin: 0px;
      color: $headerTextRed;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      line-height: 32px;
      font-weight: 400;
    }
  }
  .ir-links-image,
  .mf_table-element {
    width: 100%;
    border: 1px solid $borderColor !important;
    &:not(:last-child) {
      border-bottom: none;
    }
  }
  &__items__item,
  .mfn-row,
  .mf_table-element,
  tr {
    position: relative;
    cursor: pointer;
    @include flexPosition(start, unset, false);
    padding: 15px;
    height: 136px;
    transition: all 0.3s ease-in;
    flex-direction: column;
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    &-container {
      width: 80%;
      @include flexPosition(center, unset, true);
    }
    &-arrow {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .mfn-date,
  .mfn-title {
    width: 100%;
  }
  .event_links {
    display: flex;
    flex-wrap: wrap;
    a {
      margin-right: 8px;
      transition: all 0.2s ease;
      text-decoration: underline;
      &:hover {
        color: $headerTextRed;
      }
      &::after {
        display: none !important;
      }
    }
  }
  .mfn-title{
    color: #382e2e;
    @include flexPosition(unset, center, false);
    @include font-size($mediumTextSize);
    letter-spacing: normal;
    line-height: 19px;
  }
  .mfn-title,
  &__items__item-text-title,
  .event_links {
    a,
    p,
    .event_title,
    .event_date {
      color: $darkParagraphText;
      @include flexPosition(unset, center, false);
      @include font-size($mediumTextSize);
      letter-spacing: normal;
      line-height: normal;

      &::after {
        position: absolute;
        content: "\f054";
        font-family: $FontAwesome;
        display: block;
        font-weight: $fontWeight600;
        padding-left: 10px;
        font-size: 12px;
        color: $headerTextRed;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__items__item-text-date,
  .mfn-date,
  .event_period,
  .event_category {
    color: $darkParagraphText;
    @include font-size($smalTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 5px;
  }

  &-arrow {
    @include flexPosition(flex-end, center, false);
    width: 20%;
  }

  .mfn-pagination {
    display: none;
  }

  &__link {
    @include flexPosition(flex-end, unset, false);
    margin: 20px 9px 20px 0px;
    @media only screen and (max-width: 769px) {
      margin: 20px 0;
    }
  }

  table {
    width: 100%;
    height: 100%;
  }
  thead,
  #mf_calendar_top_buttons{
    display: none !important;
  }
  #mf_calendar_buttons {
    padding-top: 20px;
  }
  .mf-calendar-table-short {
    tr {
      width: 100%;
      border: 1px solid $borderColor;
      &:not(:last-child){
        border-bottom: none;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .report-news {
    &__items {
      min-height: auto;
    }
    .col-lg-4 {
      &:not(:first-child) {
        margin-top: 80px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

.mfn-tag {
  @include font-size($smalTextSize);
}
