@media (max-width: 1400px) {
  .header {
    width: 100%;
    position: fixed !important;
  }
  .nav__navbar-dropdown {
    width: 100vw;
  }
}
@media (max-width: 1025px) {
  .header {
    .navbar {
      display: none;
      .nav__info {
        display: none;
      }
      .nav__logo-wrapper {
        margin-left: 20px;
      }
      .nav__dropdown-close-section {
        @include flexPosition("flex-end", center, false);
      }
    }
  }
}
