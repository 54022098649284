.imageheader {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: black;
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    animation: nolatoFadeIn 500ms;

    &--text{
      width: 30em;
      position:absolute;
      top: 0;
      left: 0;
      z-index: 100;
      color: #fff;
      padding: 1rem;
      span{
        font-size: $smalTextSize;
        line-height: 1;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        185.28deg,
        transparent,
        rgba(0, 0, 0, 0.05) 57.61%,
        rgba(0, 0, 0, 0.9) 99.95%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.5) 0
      ),
      linear-gradient(
        180.33deg,
        transparent,
        rgba(0, 0, 0, 0) 55.61%,
        rgba(0, 0, 0, 0.9) 99.95%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.5) 0
      );
    bottom: 0px;
  }

  &--small {
    height: 480px;
    &::after {
      background: linear-gradient(
          186.28deg,
          transparent 63%,
          rgba(0, 0, 0, 0.05) 64.61%,
          rgba(0, 0, 0, 0.9) 99.95%,
          rgba(0, 0, 0, 0.5) 100%,
          rgba(0, 0, 0, 0.5) 0
        ),
        linear-gradient(
          180.33deg,
          transparent 53%,
          rgba(0, 0, 0, 0) 54.61%,
          rgba(0, 0, 0, 0.9) 99.95%,
          rgba(0, 0, 0, 0.5) 100%,
          rgba(0, 0, 0, 0.5) 0
        );
    }
  }
  &--large {
    height: 810px;
  }

  &__video {
    .videoContainer {
      height: 100%;
      bottom: 0;
      right: 0;
      @include flexPosition(center, center, true);
      overflow: hidden;
    }
    iframe,
    video {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
    .container {
      position: absolute !important;
      top: 0;
      width: 100%;
      right: 0;
      left: 0;
    }
  }
  &__return-button {
    z-index: 100;
    position: absolute;
    top: 25px;
    left: 35px;
    transition: all 0.2s;
    a {
      display: flex;
    }
    span {
      color: $whiteTextColor;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight500;
      letter-spacing: 0;
      line-height: 16px;
      cursor: pointer;

      &::before {
        content: "\f053";
        font-family: $FontAwesome;
        font-weight: $fontWeight600;
        padding-right: 15px;
        font-size: 12px;
      }
    }
  }
  .container {
    @include padding-bottom(68px);
    position: relative;
    z-index: 5;
  }
  .container,
  .row {
    height: 100%;
  }
  .row {
    align-content: flex-end;
  }
  &__topic-text {
    h4,
    h1 {
      color: $whiteTextColor;
      @include font-size($smalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 2px;
      line-height: 18px;
    }
  }
  &__header-text {
    position: relative;
    h2,
    h1 {
      color: $whiteTextColor;
      @include font-size($extraBigHeaderText);
      letter-spacing: 0;
      line-height: 58px;
      font-weight: $fontWeight300;

      &::before {
        position: absolute;
        display: inline-block;
        content: "";
        border-top: 2px solid $headerTextRed;
        width: 14%;
        left: -140px;
        top: 50%;
      }
    }
  }
  &__body-text {
    width: 90%;
    padding-left: 4px;
    p {
      @include font-size(19px);
      color: $whiteTextColor;
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 27px;
    }
  }
  &__button {
    @include flexPosition(flex-end, unset, false);
  }

  p {
    color: $whiteTextColor;
    font-size: 18px;
    @include font-size($smalHeaderText);
    letter-spacing: 0;
    line-height: 27px;
  }

  &__search-container {
    z-index: 1;
    height: 100%;
    @include flexPosition(center, center, true);
    h4 {
      color: $whiteTextColor;
      @include font-size($mediumHeaderText);
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
    &-input {
      margin-top: 20px;
      position: relative;
      input {
        width: 581px;
        height: 60px;
        border: 1px solid $borderColor;
        border-radius: 1px;
        color: $lightHeaderColor;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
        background-color: $whiteTextColor;
        padding-left: 20px;
        outline: none;
      }
      &::before {
        content: "\f002";
        position: absolute;
        right: 0px;
        height: 100%;
        @include flexPosition(unset, center, false);
        width: 50px;
        background: #fff;
        font-family: Font awesome\5 Free;
        font-family: "Font Awesome\ 5 Free";
        font-weight: $fontWeight600;
        color: $mediumDarkTextColor;
        @include font-size($largeHeaderText);
      }
    }
  }
}

.imageheader {
  @media (max-width: 1399px) {
    .imageheader__header-text {
      h1,
      h2 {
        line-height: normal;
        &::before {
          width: 3.5rem;
          left: -56px;
        }
      }
    }
    .videoContainer {
      iframe,
      video {
        top: 53%;
        left: 50%;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    margin-top: 128px;
  }
  @media only screen and (max-width: 1100px) {
    margin-top: 110px;
  }
  @media only screen and (max-width: 1026px) {
    margin-top: 110px;
  }
  @media (max-width: 1399px) {
    .videoContainer {
      iframe,
      video {
        top: 58%;
        left: 50%;
      }
    }
  }
  @media (max-width: 767px) {
    .row {
      padding: 0 2rem;
    }
    .videoContainer {
      iframe,
      video {
        top: 50%;
        left: 50%;
      }
    }
    &__button {
      margin-top: 20px;
      justify-content: flex-start;

      .imageheader__button {
        max-width: 370px;
      }
    }
  }
  @media (max-width: 1025px) {
    height: 600px;

    &--small {
      height: 450px;
    }

    &__search-container {
      width: 100%;

      &-input {
        input {
          width: 100%;
        }
      }
    }

    .videoContainer {
      iframe,
      video {
        // top: 53%;
        left: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    background-color: $whiteBackgroundColor;
    margin-top: 110px;
    &__image {
      position: relative;
      &--text{
        position: unset;
        width: 100%;
        font-style:italic;
        color: $darkHeaderTextColor;
        opacity: 1;
        padding: 0 0 0.5rem 0;
        margin-top: -15px;
      }
    }
    .row {
      padding: 0;
    }
    &__return-button {
      background-color: rgba(48, 32, 32, 0.76);
      top: 14px;
      left: 11px;
      padding: 4px;
    }
    .videoContainer {
      display: none;
    }
    &--large,
    &--small {
      height: auto;
    }
    .container {
      margin-top: 20px;
      padding-bottom: 0px;
    }
    .btn-container{
      width: 100%;
    }
    &__topic-text,
    &__header-text,
    &__body-text {
      width: 100%;
      h4,
      h2,
      h1,
      p {
        color: $darkParagraphText !important;
      }
      h1,
      h2 {
        &::before {
          display: none;
        }
      }
    }
    &__topic-text {
      h4,
      h1 {
        color: $headerTextRed !important;
      }
    }

    &::after {
      display: none;
    }

    &__video {
      .container {
        position: relative !important;
      }
    }
  }
}
