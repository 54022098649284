.imagecard {
  .chips,
  .tags {
    padding: 0px;
    @include margin-top(20px);
  }
  &__right {
    @include flexPosition(flex-end, flex-end, false);
  }
  &__row {
    @include margin-top(30px);
    margin-right: 0;
    margin-left: 0;
  }
  &__header,
  &__sub-header {
    @include margin-bottom(30px);
    @include flexPosition(unset, flex-end, false);
  }

  &__header {
    &-title {
      color: $darkHeaderTextColor;
      font-size: 26px;
      @include font-size($mediumHeaderText);
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  &__sub-header * {
    @include font-size($mediumSmalTextSize);
  }
  &__sections-header {
    margin-bottom: 25px;
    &-text {
      @include font-size($mediumHeaderText);
      color: $darkHeaderTextColor;
      letter-spacing: 0;
      line-height: 40px;
    }
  }
  &__sections-info {
    &-text {
      text-transform: uppercase;
      color: $headerTextRed;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      line-height: 16px;
    }
  }
  &__col {
    padding: 0px !important;
  }

  &__wrapper {
    border: 1px solid #ede9e8;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $darkHeaderTextColor;
    padding: 30px 25px 0px 25px !important;
    position: relative;
  }

  &__image-container {
    width: 100%;
    height: 225px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__container {
    width: 100%;

    &--small {
      height: 260px;
    }
  }
  &__title {
    @include margin-top(20px);
    overflow: hidden;
  }
  &__date {
    color: $headerTextRed !important;
    @include font-size($mediumSmalTextSize);
    font-weight: $fontWeight600;
    letter-spacing: 1.5px;
    line-height: 16px;
    margin-bottom: 10px;
    &.ir-imagecard{
      min-height: 16px;
    }
  }
  &__title-header {
    font-size: $largeHeaderText;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0px;
  }
  &__text {
    min-height: 110px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
  }
  &__text > * {
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
  }
  &__button {
    width: 100%;
    padding: 20px 0px;
    @include flexPosition(flex-end, unset, false);
    cursor: pointer;
    a {
      position: absolute;
      bottom: 12px;
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      color: $headerTextRed;
    }
    .button-container {
      width: 32%;
      @include flexPosition(space-between, center, false);
    }
  }
  &__button +  &__button{
   a{
    bottom: 40px;
   }
  }
  &.ir-imagecard-right {
    .imagecard__row{
      @include margin-top(20px);
    }
   
    .imagecard__col{
      margin-bottom:25px;
    }
    .imagecard__wrapper {
      border: none;    
      padding: 0 0 0 0 !important;
    
    }
    .imagecard__text{
      min-height: auto;
    }
  }
}
.imagecard__small {
  .imagecard__image-container {
    height: 190px;
  }
}
@media only screen and (max-width: 767px) {
  .imagecard {
    &__col {
      border: none;
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
    .container {
      max-width: 100%;
    }
    &__wrapper {
      padding: 0px !important;
      border: none;
    }
    &__sections-header {
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
    }
    &__sections-info {
      text-align: center;
      width: 100%;
      @include margin-bottom(25px);
    }
  }
}
@media only screen and (max-width: 600px) {
  .imagecard {
    &__text {
      min-height: auto;
    }
    &__right {
      justify-content: flex-start;
    }
  }
}

.whitepapers,
.Downloadfile {
  .imagecard__image-container {
    height: 240px;
  }
  .imagecard__title-header {
    height: 96px;
    overflow: hidden;
  }
  .imagecard__date {
    text-transform: uppercase;
  }
}
.Downloadfile {
  .imagecard__button {
    padding: 8px 0px;
    a {
      position: relative;
    }
  }
}
