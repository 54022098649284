.filteredContacts {
  .form-group {
    padding: 0px 15px;
  }
  .text-title__optional {
    margin-bottom: 10px;
  }
  &__row {
    @include margin-top(30px);
  }

  &__contact-modal {
    animation: nolatoFadeIn 500ms ease-in;
  }

  &__output {
    @include margin-top(30px);
    padding-top: 20px;
    animation: nolatoFadeIn 500ms ease-in;

    &--header {
      border-bottom: 1px solid $headerTextRed;
      @include margin-bottom(20px);
    }

    &--body {
      p {
        padding-bottom: 10px;
      }
    }
    &--item {
      padding-bottom: 2px;
    }
  }
  @media only screen and (max-width: 600px) {
    .form-group {
      width: 100% !important;
      margin-right: 0px;
    }
    .text-title__container {
      width: 100%;
    }
    &__output {
      margin-top: 0px;
    }
  }
}

.two-column {
  .filteredContacts {
    div[class*="col"] {
      width: 100%;
      max-width: 100%;
      flex: none;
      padding: 0px;
    }

    .form-group {
      width: 45% !important;
      margin-right: 20px;
    }
    @media only screen and (max-width: 600px) {
      .form-group {
        width: 100% !important;
        margin-right: 0px;
      }
      .text-title__container {
        width: 100%;
      }
      &__output {
        margin-top: 0px;
      }
    }
  }
}
