.relatedPage {
  .chips,
  .tags {
    padding: 0px;
  }
  &__header,
  &__sub-header {
    margin-bottom: 31px;
    @include flexPosition(unset, flex-end, false);
  }
  &__header {
    &-title {
      color: $darkHeaderTextColor;
      @include font-size($mediumHeaderText);
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  &__sub-header * {
    @include font-size($mediumSmalTextSize);
  }
  &__sections-header {
    margin-bottom: 25px;
    &-text {
      @include font-size($mediumHeaderText);
      color: $darkHeaderTextColor;
      letter-spacing: 0;
      line-height: 40px;
    }
  }
  &__sections-info {
    &-text {
      text-transform: uppercase;
      color: $headerTextRed;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      line-height: 16px;
    }
  }
  &__col {
    padding: 0px !important;
  }

  &__wrapper {
    border: 1px solid #ede9e8;
    width: 100%;
    max-width: 470px;
    height: auto;
    text-decoration: none;
    color: $darkHeaderTextColor;
    padding: 30px 25px 0px 25px !important;
  }

  &__image-container {
    width: 100%;
    height: 250px;
    overflow: hidden;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__container {
    width: 100%;

    &--small {
      height: 260px;
    }
  }
  &__title {
    @include margin-top(20px);
    overflow: hidden;
  }
  &__date {
    color: $headerTextRed !important;
    @include font-size($smalTextSize);
    font-weight: $fontWeight600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 10px;
  }
  &__title-header {
    font-size: $largeHeaderText;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0px;
  }
  &__text {
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  &__text > * {
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0px;
  }
  &__button {
    width: 100%;
    margin: 20px 0px;
    @include flexPosition(flex-end, unset, false);
    cursor: pointer;
    a {
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      color: $headerTextRed;
    }
    .button-container {
      width: 32%;
      @include flexPosition(space-between, center, false);
    }
  }
}

@media only screen and (max-width: 769px) {
  .relatedPage {
    &__col {
      border: none;
      &:not(:first-child) {
        @include margin-top(40px);
      }
    }
    .container {
      max-width: 100%;
    }

    &__container {
      padding: 0px 20px 20px 20px;
    }
    &__wrapper {
      padding: 0px !important;
      width: 100%;
      min-width: 100%;
    }
    &__sections-header {
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
    }
    &__sections-info {
      text-align: center;
      width: 100%;
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 460px) {
  .relatedPage {
    &__image-container {
      height: 200px;
    }
  }
}
