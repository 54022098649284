.read-card {
  min-width: 210px;
  &__title {
    h3 {
      color: #d1291c;
      letter-spacing: 0;
      line-height: 40px;
      font-weight: 400;
    }
  }

  &__wrapper {
    @include margin-top(20px);
  }

  &__container {
    height: 330px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 25px;
    border: 1px solid $borderColor;
    cursor: pointer;
    &:hover {
      p {
        color: $headerTextRed;
      }
    }
    &-box {
      height: 85%;
      position: relative;
      overflow: hidden;
    }
    &-image {
      width: 100%;
      height: 100% !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      object-fit: contain !important;
      transition: all 0.3s ease-in-out;
    }
    &-info {
      width: 100%;
      p {
        text-align: center;
        padding: 5px 0px 0px 5px;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &__selection {
    @include margin-top(32px);
    &-title {
      @include margin-bottom(14px);
      h3 {
        @include font-size($mediumSmalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 1.5px;
        line-height: 16px;
      }
    }
    &-select{
      @include font-size($smalTextSize !important);
      option {
        @include font-size($smalTextSize !important);
      }
    }
  }
}

@media (max-width: 768px) {
  .read-card {
    &__selection {
      width: 60%;
    }
  }
}

@media (max-width: 440px) {
  .read-card {
    &__container {
      padding: 0px;
      border: none;
    }
    &__selection {
      width: 100%;
    }
  }
}
