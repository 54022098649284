#CybotCookiebotDialogBody {
  max-width: 1200px !important;
}

#CybotCookiebotDialogBodyContentTitle {
  @include font-size($font-size-6!important);
  font-weight: $fontWeight400 !important;
  line-height: normal !important;
  color: $blackColor !important;
}

#CybotCookiebotDialogBodyContentText {
  color: $darkParagraphText !important;
  font-size: $mediumTextSize !important;
  font-weight: $fontWeight300 !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  margin: 0px !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyLevelButtons {
  display: none !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  width: auto !important;
  height: auto !important;
  @include font-size($mediumTextSize !important);
  position: relative !important;
  z-index: 1 !important;
  padding: 18px 40px !important;
  display: inline-block !important;
  @include margin-bottom(16px !important);
  @include margin-top(16px!important);
  border: 2px solid $headerTextRed !important;
  cursor: pointer !important;
  text-decoration: none !important;
  font-weight: $fontWeight500 !important;
  background-color: $headerTextRed !important;
  color: #fff !important ;
  transition: all 0.2s !important;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #e93e32;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  width: auto !important;
  height: auto !important;
  @include font-size($mediumTextSize!important);
  padding: 18px 40px !important;
  position: relative !important;
  z-index: 1 !important;
  display: inline-block !important;
  @include margin-bottom(16px!important);
  @include margin-top(16px!important);
  border: 2px solid $headerTextRed !important;
  cursor: pointer !important;
  text-decoration: none !important;
  font-weight: $fontWeight500 !important;
  color: $headerTextRed !important;
  background-color: #fdf7f6 !important;
  transition: all 0.2s !important;
  white-space: nowrap !important;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }
  &:hover {
    color: $headerTextRed !important;
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}
