.three-column {
  .image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 58%;
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .view-card {
    a {
      width: 100%;
      min-width: 100%;
    }
  }
  .col-lg-4 {
    @media only screen and (max-width: 991px) {
      &:not(:first-child) {
        @include padding-top(15px);
      }
    }
  }
}
