$toolbarTextGray: #f9f9f9;
$headerTextRed: #d1291c;
$grayButtonColor: #6b6b6b;
$darkHeaderTextColor: #302020;
$darkParagraphText: #382e2e;
$lightHeaderColor: #b3a9a8;
$backGroundColorDropDown: #f7f2f2;
$mediumDarkTextColor: #544c4c;
$footerLighttext: #e8e8e8;
$whiteTextColor: #fff;
$blackColor: #262626;
$darkGreyColor: #544c4c;
$mediumGrayColor: #8f8f8f;
$greenColor: #6bce50;
$GrayColor: #b3a9a8;
$SubmenuRed: #E60000;

$verySmalTextSize: 11px;
$mediumSmalTextSize: 12px;
$smalTextSize: 14px;
$textSize: 15px;
$mediumTextSize: 16px;

$smalHeaderText: 18px;
$font-size-6: 20px;
$largeHeaderText: 22px;
$mediumHeaderText: 26px;
$headerText30: 30px;
$bigHeaderText: 32px;
$extraBigHeaderText: 44px;

$fontWeight300: 300;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
$fontWeight800: 800;
$fontWeight900: 900;

@mixin font-size($value) {
  @include rfs($value);
}

$FontAwesome: "Font Awesome 5 Free";
$FontAwesomeBrands: "Font Awesome 5 Brands";

$svgRedColor: invert(24%) sepia(74%) saturate(2458%) hue-rotate(351deg)
  brightness(95%) contrast(95%);
$svgDarkParagraphText: invert(13%) sepia(2%) saturate(7493%) hue-rotate(314deg)
  brightness(82%) contrast(79%);
$svgWhiteColor: invert(97%) sepia(1%) saturate(1647%) hue-rotate(353deg)
  brightness(127%) contrast(90%);
$svgWhiteColorSocialMedia: invert(97%) sepia(0%) saturate(55%)
  hue-rotate(228deg) brightness(91%) contrast(91%);

$borderColor: #ede9e8;
$footerBorderColor: #382e2e;
$lightBorderColor: #e0dada;
$grayBorderColor: #b3a9a8;
$mediumGrayColor: #8f8f8f;
$redBorderColor: #d1291c;

$brownBackgroundColor: #302020;
$backgroundRedGradient: linear-gradient(214.43deg, #d1291c 0%, #ad2217 100%);
$blackBackGroundColor: #0d0e11;
$lightBackgroundColor: #faf8f7;
$whiteBackgroundColor: #fff;
$redBackgroundColor: #b3221d;
$grayBackgroundColor: #f9f9f8;
$lightRedBackgroundColor: #d1291c;
$whiteMediumBackgroundColor: #f7f2f2;
$hoverGrey : #e7e7e7;

// SVG Background Icons
$chevronBlack : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='Icon_feather-chevron-down' data-name='Icon feather-chevron-down' d='M13,18.5a1,1,0,0,1-.707-.293l-4-4a1,1,0,0,1,1.414-1.414L13,16.086l3.293-3.293a1,1,0,1,1,1.414,1.414l-4,4A1,1,0,0,1,13,18.5Z' transform='translate(-8 -12.5)'/%3E%3C/svg%3E%0A");
$chevronGray : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='Icon_feather-chevron-down' data-name='Icon feather-chevron-down' d='M13,18.5a1,1,0,0,1-.707-.293l-4-4a1,1,0,0,1,1.414-1.414L13,16.086l3.293-3.293a1,1,0,1,1,1.414,1.414l-4,4A1,1,0,0,1,13,18.5Z' transform='translate(-8 -12.5)' fill='%23382e2e'/%3E%3C/svg%3E%0A");
$chevronRed : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='Icon_feather-chevron-down' data-name='Icon feather-chevron-down' d='M13,18.5a1,1,0,0,1-.707-.293l-4-4a1,1,0,0,1,1.414-1.414L13,16.086l3.293-3.293a1,1,0,1,1,1.414,1.414l-4,4A1,1,0,0,1,13,18.5Z' transform='translate(-8 -12.5)' fill='%23d1291c'/%3E%3C/svg%3E%0A");
$chevronWhite : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath id='Icon_feather-chevron-down' data-name='Icon feather-chevron-down' d='M13,18.5a1,1,0,0,1-.707-.293l-4-4a1,1,0,0,1,1.414-1.414L13,16.086l3.293-3.293a1,1,0,1,1,1.414,1.414l-4,4A1,1,0,0,1,13,18.5Z' transform='translate(-8 -12.5)' fill='%23fff'/%3E%3C/svg%3E%0A" );
$redArrow : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath id='Icon_ionic-md-arrow-back' data-name='Icon ionic-md-arrow-back' d='M17.977,11.227H8.864l4.2-4.2-1.088-1.05-6,6,6,6,1.05-1.05-4.162-4.2h9.112Z' transform='translate(17.977 17.977) rotate(180)' fill='%23d70007'/%3E%3C/svg%3E%0A");
$whiteArrow : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath d='m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z' fill='%23ffffff'/%3E%3C/svg%3E"); 
$searchBlack : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15.999' viewBox='0 0 16 15.999'%3E%3Cpath id='Union_4' data-name='Union 4' d='M-1820.207-81.793l-2.723-2.722a7.186,7.186,0,0,1-4.349,1.459,7.23,7.23,0,0,1-7.222-7.222,7.23,7.23,0,0,1,7.222-7.222,7.23,7.23,0,0,1,7.223,7.222,7.186,7.186,0,0,1-1.459,4.349l2.722,2.723a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.292A1,1,0,0,1-1820.207-81.793Zm-12.293-8.485a5.228,5.228,0,0,0,5.222,5.223,5.205,5.205,0,0,0,3.622-1.463c.021-.025.042-.049.066-.072s.047-.045.072-.066a5.2,5.2,0,0,0,1.463-3.622,5.228,5.228,0,0,0-5.223-5.222A5.228,5.228,0,0,0-1832.5-90.278Z' transform='translate(1834.5 97.5)'/%3E%3C/svg%3E%0A");
$searchWhite : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15.999' viewBox='0 0 16 15.999'%3E%3Cpath id='Union_4' data-name='Union 4' d='M-1820.207-81.793l-2.723-2.722a7.186,7.186,0,0,1-4.349,1.459,7.23,7.23,0,0,1-7.222-7.222,7.23,7.23,0,0,1,7.222-7.222,7.23,7.23,0,0,1,7.223,7.222,7.186,7.186,0,0,1-1.459,4.349l2.722,2.723a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.292A1,1,0,0,1-1820.207-81.793Zm-12.293-8.485a5.228,5.228,0,0,0,5.222,5.223,5.205,5.205,0,0,0,3.622-1.463c.021-.025.042-.049.066-.072s.047-.045.072-.066a5.2,5.2,0,0,0,1.463-3.622,5.228,5.228,0,0,0-5.223-5.222A5.228,5.228,0,0,0-1832.5-90.278Z' transform='translate(1834.5 97.5)' fill='%23fff'/%3E%3C/svg%3E%0A");
$searchRed : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15.999' viewBox='0 0 16 15.999'%3E%3Cpath id='Union_4' data-name='Union 4' d='M-1820.207-81.793l-2.723-2.722a7.186,7.186,0,0,1-4.349,1.459,7.23,7.23,0,0,1-7.222-7.222,7.23,7.23,0,0,1,7.222-7.222,7.23,7.23,0,0,1,7.223,7.222,7.186,7.186,0,0,1-1.459,4.349l2.722,2.723a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.292A1,1,0,0,1-1820.207-81.793Zm-12.293-8.485a5.228,5.228,0,0,0,5.222,5.223,5.205,5.205,0,0,0,3.622-1.463c.021-.025.042-.049.066-.072s.047-.045.072-.066a5.2,5.2,0,0,0,1.463-3.622,5.228,5.228,0,0,0-5.223-5.222A5.228,5.228,0,0,0-1832.5-90.278Z' transform='translate(1834.5 97.5)' fill='%23d70007'/%3E%3C/svg%3E%0A");
$plusGray : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg id='Union_5' data-name='Union 5' transform='translate(3996.001 74)' fill='%23fff'%3E%3Cpath d='M -3990.8759765625 -64.50051879882812 L -3991.1259765625 -64.50051879882812 L -3991.1259765625 -68.37551116943359 L -3991.1259765625 -68.87551116943359 L -3991.6259765625 -68.87551116943359 L -3995.5009765625 -68.87551116943359 L -3995.5009765625 -69.12532806396484 L -3991.6259765625 -69.12532806396484 L -3991.1259765625 -69.12532806396484 L -3991.1259765625 -69.62532806396484 L -3991.1259765625 -73.50032043457031 L -3990.8759765625 -73.50032043457031 L -3990.8759765625 -69.62532806396484 L -3990.8759765625 -69.12532806396484 L -3990.3759765625 -69.12532806396484 L -3986.5009765625 -69.12532806396484 L -3986.5009765625 -68.87551116943359 L -3990.3759765625 -68.87551116943359 L -3990.8759765625 -68.87551116943359 L -3990.8759765625 -68.37551116943359 L -3990.8759765625 -64.50051879882812 Z' stroke='none'/%3E%3Cpath d='M -3990.3759765625 -64.00051879882812 L -3991.6259765625 -64.00051879882812 L -3991.6259765625 -68.37551116943359 L -3996.0009765625 -68.37551116943359 L -3996.0009765625 -69.62532806396484 L -3991.6259765625 -69.62532806396484 L -3991.6259765625 -74.00032043457031 L -3990.3759765625 -74.00032043457031 L -3990.3759765625 -69.62532806396484 L -3986.0009765625 -69.62532806396484 L -3986.0009765625 -68.37551116943359 L -3990.3759765625 -68.37551116943359 L -3990.3759765625 -64.00051879882812 Z' stroke='none' fill='%23707070'/%3E%3C/g%3E%3C/svg%3E%0A");
$plusWhite : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg id='Union_5' data-name='Union 5' transform='translate(3996.001 74)' fill='%23fff'%3E%3Cpath d='M -3990.8759765625 -64.50051879882812 L -3991.1259765625 -64.50051879882812 L -3991.1259765625 -68.37551116943359 L -3991.1259765625 -68.87551116943359 L -3991.6259765625 -68.87551116943359 L -3995.5009765625 -68.87551116943359 L -3995.5009765625 -69.12532806396484 L -3991.6259765625 -69.12532806396484 L -3991.1259765625 -69.12532806396484 L -3991.1259765625 -69.62532806396484 L -3991.1259765625 -73.50032043457031 L -3990.8759765625 -73.50032043457031 L -3990.8759765625 -69.62532806396484 L -3990.8759765625 -69.12532806396484 L -3990.3759765625 -69.12532806396484 L -3986.5009765625 -69.12532806396484 L -3986.5009765625 -68.87551116943359 L -3990.3759765625 -68.87551116943359 L -3990.8759765625 -68.87551116943359 L -3990.8759765625 -68.37551116943359 L -3990.8759765625 -64.50051879882812 Z' stroke='none'/%3E%3Cpath d='M -3990.3759765625 -64.00051879882812 L -3991.6259765625 -64.00051879882812 L -3991.6259765625 -68.37551116943359 L -3996.0009765625 -68.37551116943359 L -3996.0009765625 -69.62532806396484 L -3991.6259765625 -69.62532806396484 L -3991.6259765625 -74.00032043457031 L -3990.3759765625 -74.00032043457031 L -3990.3759765625 -69.62532806396484 L -3986.0009765625 -69.62532806396484 L -3986.0009765625 -68.37551116943359 L -3990.3759765625 -68.37551116943359 L -3990.3759765625 -64.00051879882812 Z' stroke='none' fill='%23ff'/%3E%3C/g%3E%3C/svg%3E%0A");
$minusWhite : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='1.25' viewBox='0 0 10 1.25'%3E%3Cpath id='Union_2' data-name='Union 2' d='M-3991.625-74h1.25v10h-1.25Z' transform='translate(-64 3991.625) rotate(90)' fill='%23fff'/%3E%3C/svg%3E%0A");
$gridRed : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'%3E%3Cg id='Icon_feather-grid' data-name='Icon feather-grid' transform='translate(-4 -4)'%3E%3Cpath id='Path_8' data-name='Path 8' d='M4.5,4H9.167a.5.5,0,0,1,.5.5V9.167a.5.5,0,0,1-.5.5H4.5a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,4.5,4ZM8.667,5H5V8.667H8.667Z' fill='%23d70007'/%3E%3Cpath id='Path_9' data-name='Path 9' d='M21,4h4.667a.5.5,0,0,1,.5.5V9.167a.5.5,0,0,1-.5.5H21a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,21,4Zm4.167,1H21.5V8.667h3.667Z' transform='translate(-9.167)' fill='%23d70007'/%3E%3Cpath id='Path_10' data-name='Path 10' d='M21,20.5h4.667a.5.5,0,0,1,.5.5v4.667a.5.5,0,0,1-.5.5H21a.5.5,0,0,1-.5-.5V21A.5.5,0,0,1,21,20.5Zm4.167,1H21.5v3.667h3.667Z' transform='translate(-9.167 -9.167)' fill='%23d70007'/%3E%3Cpath id='Path_11' data-name='Path 11' d='M4.5,20.5H9.167a.5.5,0,0,1,.5.5v4.667a.5.5,0,0,1-.5.5H4.5a.5.5,0,0,1-.5-.5V21A.5.5,0,0,1,4.5,20.5Zm4.167,1H5v3.667H8.667Z' transform='translate(0 -9.167)' fill='%23d70007'/%3E%3C/g%3E%3C/svg%3E%0A");
$closeBlack : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.8' height='19.799' viewBox='0 0 19.8 19.799'%3E%3Cpath id='Union_3' data-name='Union 3' d='M-3978.322-102.2l-7.779,7.778-2.121-2.121,7.779-7.779-7.778-7.778,2.121-2.121,7.778,7.778,7.778-7.778,2.121,2.121-7.778,7.778,7.779,7.779-2.122,2.121Zm7.778,7.071,1.414-1.414-1.414,1.414-7.778-7.779-7.779,7.779-1.413-1.414,1.413,1.414,7.779-7.779Zm-6.364-9.193,7.778-7.778Zm-10.607-7.778,7.778,7.778Z' transform='translate(3988.222 114.222)' fill='%233c3c3c'/%3E%3C/svg%3E%0A");
$closeRed : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.8' height='19.799' viewBox='0 0 19.8 19.799'%3E%3Cpath id='Union_3' data-name='Union 3' d='M-3978.322-102.2l-7.779,7.778-2.121-2.121,7.779-7.779-7.778-7.778,2.121-2.121,7.778,7.778,7.778-7.778,2.121,2.121-7.778,7.778,7.779,7.779-2.122,2.121Zm7.778,7.071,1.414-1.414-1.414,1.414-7.778-7.779-7.779,7.779-1.413-1.414,1.413,1.414,7.779-7.779Zm-6.364-9.193,7.778-7.778Zm-10.607-7.778,7.778,7.778Z' transform='translate(3988.222 114.222)' fill='%23d70007'/%3E%3C/svg%3E%0A");
$backBlack : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath id='Icon_ionic-md-arrow-back' data-name='Icon ionic-md-arrow-back' d='M25.977,14.727H10.789l7-7-1.813-1.75-10,10,10,10,1.75-1.75-6.937-7H25.977Z' transform='translate(-5.977 -5.977)' fill='%233c3c3c'/%3E%3C/svg%3E%0A");
$languageSelector : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath d='m476-80 182-480h84L924-80h-84l-42-122H604L560-80h-84Zm152-192h144l-70-198h-4l-70 198Zm-468 72-56-56 202-202q-38-42-66.5-87T190-640h84q18 36 38.5 65t49.5 61q44-48 73-98.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 71-57 138t-89 126l96 98-30 82-124-124-200 200Z'/%3E%3C/svg%3E");
$homeBlack : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12'%3E%3Cpath id='Icon_ionic-md-home' data-name='Icon ionic-md-home' d='M8.375,16.5v-4h3v4h3.05v-6h1.95l-6.5-6-6.5,6h1.95v6Z' transform='translate(-3.375 -4.5)' fill='%233c3c3c'/%3E%3C/svg%3E%0A");
$homeGray : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12'%3E%3Cpath id='Icon_ionic-md-home' data-name='Icon ionic-md-home' d='M8.375,16.5v-4h3v4h3.05v-6h1.95l-6.5-6-6.5,6h1.95v6Z' transform='translate(-3.375 -4.5)' fill='%23989898'/%3E%3C/svg%3E%0A");


// Breakpoints
$breakpoint-cellphone : 700px;
$breakpoint-tablets : 1100px;
$breakpoint-mediumscreen : 1300px;

.nolato-border {
  border: 1px solid $borderColor;
  &__right {
    border-right: 1px solid $borderColor;
  }
  &__left {
    border-left: 1px solid $borderColor;
  }
  &__top {
    border-top: 1px solid $borderColor;
  }
  &__bottom {
    border-bottom: 1px solid $borderColor;
  }
}
.no-border {
  border: none !important;
}

@media only screen and (max-width: 767px) {
  .nolato-border {
    &__right {
      border-right: none;
      border-bottom: 1px solid $borderColor;
    }
    &__left {
      border-left: none;
    }
  }
}
