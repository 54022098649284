@media print {
  #wrapper * {
    display: none;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .imageheader * {
    display: block !important;
  }
  .imageheader {
    display: block !important;
    height: auto;
    width: 100%;
    div[class*="col"] {
      flex: 100%;
      max-width: 100%;
    }
    &::after {
      display: none !important;
    }
    .container {
      padding-bottom: 20px;
    }
    .imageheader__image,
    .imageheader__button,
    .imageheader__return-button {
      display: none !important;
    }
    .imageheader__header-text {
      display: block !important;
      h2,
      h4 {
        color: $darkGreyColor !important;
        font-weight: $fontWeight500;
        &::before {
          display: none;
        }
      }
    }
    .imageheader__body-text {
      display: block !important;
      p {
        color: $darkGreyColor !important;
      }
    }
  }

  .print-content {
    display: block !important;
    border: none !important;
    margin-top: 0px !important;
    width: 70%;
    div[class*="col"] {
      display: none !important;
      &:first-child {
        display: block !important;
      }
    }
    .rich__text {
      ul {
        li {
          padding-left: 16px !important;
          &::before {
            display: block !important;
            background-color: $darkGreyColor !important;
          }
        }
      }

      a {
        color: $darkGreyColor !important;
      }
    }

    .tags {
      display: none !important;
    }
  }
  .print-content * {
    display: block !important;
    page-break-before: auto;
  }
  .footer-wrapper,
  .right__column {
    display: none;
  }
}
