.nolato-radiobutton{
    &__container{
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      padding-bottom: 0px;
      margin-bottom: 0px;


      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        position: absolute;
        top: -4px;
        left: 0;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 2px solid $headerTextRed;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input:checked ~ .checkmark:after {
        transform: scale(1);
      }
      .checkmark:after {
        transform: scale(0);
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $headerTextRed;
        display: block;
        transition: transform .2s;
     }
    }
  }