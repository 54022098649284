@keyframes nolatoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes nolatoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes nolatoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 $headerTextRed;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 $headerTextRed;
    box-shadow: 0 0 0 0 $headerTextRed;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.link__hover {
  position: relative;
  transition: all 250ms ease-in;
  transition-delay: 100ms;
  cursor: pointer;
  padding-bottom: 4px;
  text-decoration: none !important;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $headerTextRed;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }
  &:hover {
    color: $headerTextRed !important;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}
