.random-content {
  height: 100%;
  max-width: 450px;
  @include flexPosition(flex-end, unset, false);
  flex-wrap: wrap;

  &__text {
    text-align: start;
    h3 {
      text-align: start;
      color: $headerTextRed;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      display: block;
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    &__link {
      color: $headerTextRed;
      align-items: flex-end !important;
    }
  }
}

@media (max-width: 769px) {
  .random-content {
    padding-bottom: 20px;
  }
}

.link__container {
  display: inline-block;
}
