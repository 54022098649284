.iframe-Flyout {
  position: fixed;
  z-index: 1700;
  height: 100vh;
  width: 50vw;
  max-width: 700px;
  top: 0;
  right: 0;
  padding: 75px 40px !important;
  background-color: $whiteBackgroundColor;
  overflow-x: hidden;
  transition: transform 0.3s ease-in;
  transform: translateX(100%);

  &__title {
    h2 {
      color: $darkHeaderTextColor;
      margin-bottom: 40px;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    display: block;
    right: 20px;
    top: 5px;
    span {
      &::after {
        content: "\f00d";
        font-family: "Font Awesome\ 5 Free";
        display: block;
        padding-top: 2px;
        font-weight: $fontWeight600;
        margin-left: 15px;
        @include font-size(40px);
        color: $headerTextRed;
      }
    }
  }
  iframe {
    @include margin-top(30px);
    width: 100%;
    height: 50%;
  }
  @media only screen and (max-width: 769px) {
    max-width: 100%;
    width: 60vw;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    width: 100vw;
    padding: 26px 15px !important;
  }
}
