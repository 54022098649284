.loading {
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__loader {
    border: 4px solid $lightBackgroundColor;
    border-radius: 50%;
    border-top: 4px solid $headerTextRed;
    border-right: 4px solid $headerTextRed;
    width: 28px;
    height: 28px;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
