.media-libary {
  &__title {
    h3 {
      color: $headerTextRed;
      @include font-size($bigHeaderText);
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  .row-menu {
    margin-top: 50px;
  }
  .col-12 {
    @include flexPosition(center, unset, false);
  }
  &__menu {
    margin-top: 50px;
    height: 50px;
    width: 100%;
    list-style: none;
    @include flexPosition(center, unset, false);
    position: relative;
    border-bottom: 2px solid $lightBorderColor;
    padding-bottom: 20px;
    @include margin-bottom(40px);

    li {
      display: block;
      position: relative;
      margin-right: 40px;
      height: inherit;
      z-index: 104;
      div {
        height: inherit;
        &:hover {
          border-bottom: 2px solid $headerTextRed;
        }
        span {
          color: $darkHeaderTextColor;
          @include font-size($smalHeaderText);
          letter-spacing: 0;
          line-height: 27px;
        }
      }
    }
    &--active {
      div {
        border-bottom: 2px solid $headerTextRed;
        z-index: 104;
      }
    }
  }

  &__media {
    div[class*="col"] {
      margin-top: 30px;
    }

    &-wrapper {
      overflow: hidden;
      position: relative;
      height: 220px;
      width: 100%;

      &-image {
        height: inherit;
        transition: transform 300ms ease-in-out;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:hover {
          transform: scale(1.2);
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.media-libary {
  @media only screen and (max-width: 769px) {
    &__media-wrapper {
      padding-top: 10px;
      height: 300px !important;
    }
  }
}
