#mf_stockCalculator {
  .mf_date-range,
  .mf_number-of-shares {
    margin-bottom: 1rem;
  }
  .mf_date-range,
  .mf_number-of-shares {
    h2 {
      @include font-size($largeHeaderText);
    }
  }
  .mf_input-wrapper + .mf_input-wrapper {
    margin-left: 1rem;
    @media only screen and (max-width: 727px) {
      margin: 0;
    }
  }
  .mf_input-wrapper{
    display:inline-block;
    
  }
  #mf_calcResult {
    tr {
      border-bottom: 1px solid $headerTextRed;
    }
    td,
    th {
      @include font-size($smalTextSize);
      &:nth-child(n + 4) {
        text-align: right;
      }
    }
    td,
    th {
      padding: 0.2rem;
    }
  }
  p {
    margin-bottom: 0.5rem;
  }
}

.hide-table-header {
  th {
    visibility: hidden;
  }
}
