.nolato-component,
.iframe-Flyout,
.contact-modal {
  .rich__text {
    h1,
    h2,
    .mfn-title {
      @include margin-bottom(12px);
      &:not(:first-child) {
        @include margin-top(12px);
      }
    }
    h3,
    h4 {
      color: $darkHeaderTextColor;
      @include margin-bottom(8px);
      line-height: normal;
      &:not(:first-child) {
        @include margin-top(20px);
      }
    }
    h1 {
      @include margin-bottom(50px);
    }
    p + * {
      @include margin-top(16px);
    }
    img {
      @include margin-top(32px);
      @include margin-bottom(32px);
    }
    &-image {
      width: 100%;
      @include margin-top(32px);
      @include margin-bottom(32px);
    }
    &-imageFloat {
      width: 50%;
      float: right;
      margin: 15px 0px 15px 15px !important;
    }
    &-imageFloat,
    &-image {
      img {
        object-fit: cover;
        width: 100%;
        margin: 0px;
      }
      &-imageInfo {
        color: $darkParagraphText;
        @include font-size($mediumSmalTextSize);
        letter-spacing: 0;
        line-height: 16px;
      }
    }
    ul {
      li {
        position: relative;
        color: $darkParagraphText !important;
        letter-spacing: 0 !important;
        padding: 1px 0px;
        text-indent: -1px;
        padding-left: 16px;

        &::before {
          content: "";
          position: absolute;
          top: 10px;
          left: 0px;
          display: block;
          background-color: $headerTextRed;
          height: 7px;
          width: 7px !important;
        }
        ul {
          padding: 0px !important;

          li {
            text-indent: 0px;
            position: relative;
            padding: 0px;
            margin-left: 15px;
            &::before {
              position: absolute;
              margin: 0px;
              top: 11px;
              width: 6px !important;
              height: 6px;
              border: 1px solid #d1291c !important;
              padding-top: 0px !important;
              left: -14px;
            }
          }
        }
      }
    }
    a {
      color: $headerTextRed !important;
      font-weight: 400;
      cursor: pointer;
    }
    img {
      height: auto !important;
      max-width: 100%;
    }
    .button {
      &-red {
        @include font-size($mediumTextSize);
        position: relative;
        z-index: 1;
        padding: 18px 40px;
        display: inline-block;
        @include margin-bottom(16px);
        @include margin-top(16px);
        border: 2px solid $headerTextRed;
        cursor: pointer;
        text-decoration: none !important;
        font-weight: $fontWeight500;
        background-color: $headerTextRed;
        color: #fff !important;
        transition: all 0.2s;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: #e93e32;
          z-index: -1;
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 250ms ease-in;
        }
        &:hover::after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
      &-white {
        @include font-size($mediumTextSize);
        padding: 18px 40px;
        position: relative;
        z-index: 1;
        display: inline-block;
        @include margin-bottom(16px);
        @include margin-top(16px);
        border: 2px solid $headerTextRed;
        cursor: pointer;
        text-decoration: none !important;
        font-weight: $fontWeight500;
        color: $headerTextRed !important;
        background-color: #fdf7f6;
        transition: all 0.2s;
        white-space: nowrap;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: -1;
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 250ms ease-in;
        }
        &:hover {
          color: $headerTextRed !important;
          &::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
    .quoute {
      color: $darkHeaderTextColor;
      @include font-size($mediumHeaderText);
      font-weight: $fontWeight500;
      letter-spacing: 0;
      line-height: 36px;
      position: relative;
    }
  }
  // .article__text {
  //   h1,
  //   h2,
  //   h3,
  //   h4 {
  //     @include margin-bottom(32px);
  //     color: $darkHeaderTextColor;
  //     font-weight: $fontWeight500;
  //     line-height: normal;
  //     &:not(:first-child) {
  //       @include padding-top(45px);
  //     }
  //   }
  //   p + p {
  //     @include margin-top(32px);
  //   }
  // }

  img {
    max-width: 100%;
    height: auto;
    pointer-events: none;
  }
  .textStyle-1 > * {
    &:not(h1, h2, h3, h4) {
      @include font-size($smalHeaderText);
      font-weight: $fontWeight300;
      color: $mediumDarkTextColor;
      line-height: 27px;
    }
  }
  .textStyle-1 {
    @include font-size($smalHeaderText);
    font-weight: $fontWeight300;
    color: $mediumDarkTextColor;
    line-height: 27px;
  }
  .textStyle-2 > * {
    &:not(h1, h2, h3, h4) {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  .textStyle-2 {
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
  }
  .textStyle-3 > * {
    &:not(h1, h2, h3, h4) {
      color: $darkHeaderTextColor;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      line-height: 32px;
      font-weight: $fontWeight400;
    }
  }
  .textStyle-3 {
    color: $darkHeaderTextColor;
    @include font-size($largeHeaderText);
    letter-spacing: 0;
    line-height: 32px;
    font-weight: $fontWeight400;
  }

  .mfn-content {
    &:not(h1, h2, h3, h4) {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  .mfn-content > * {
    &:not(h1, h2, h3, h4) {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

@media (max-width: 769px) {
  .rich__text {
    .button-red,
    .button-white {
      width: 100%;
      text-align: center;
    }
    &-imageFloat {
      width: 100% !important;
    }
  }
}
