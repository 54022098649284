.title-info-small-component {
  &__title {
    h3 {
      color: $darkHeaderTextColor;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
    }
  }

  &__name,
  &__adress,
  &__phone {
    @include font-size($mediumTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 22px;
  }
}
