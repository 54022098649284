.text-with-red-arrow-right {
  display: inline-block;
  @include font-size($smalTextSize);
  font-weight: $fontWeight600 !important;
  letter-spacing: 1.5px;
  flex-direction: row !important;
  @media only screen and (max-width: 567px) {
    @include flexPosition(unset, center, false);
  }
  &::after {
    content: "\f054";
    font-family: $FontAwesome;
    display: inline-block;
    font-weight: $fontWeight600;
    padding-left: 10px;
    font-size: 12px;
    color: $headerTextRed;
  }
}

.dark{
  color: $darkHeaderTextColor;
}

.text-with-red-big-arrow-right {
  @include flexPosition(unset, center, false);

  &::after {
    content: "\f054";
    font-family: $FontAwesome;
    display: block;
    font-weight: $fontWeight600;
    padding-left: 10px;
    @include font-size($largeHeaderText);
    color: $headerTextRed;
  }
}

.text-with-black-arrow-right {
  @include flexPosition(unset, center, false);
  &::after {
    content: "\f054";
    font-family: $FontAwesome;
    display: block;
    padding-top: 2px;
    font-weight: $fontWeight600;
    padding-left: 10px;
    font-size: 12px;
    color: $darkParagraphText;
  }
}
