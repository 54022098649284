.bigredtext-smaltext {
  text-align: left;

  &__smalheader {
    h4 {
      color: $darkParagraphText;
      @include font-size($smalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 2px;
      line-height: 18px;
    }
  }
  &__bigheader {
    @include padding-top(10px);
    h2 {
      color: $headerTextRed;
      @include font-size($extraBigHeaderText);
      letter-spacing: 0;
      line-height: 58px;
      font-weight: $fontWeight400;
    }
  }
  &__darker-text {
    color: $darkHeaderTextColor !important;
    font-weight: normal !important;
  }
  &__darker-text > * {
    color: $darkHeaderTextColor !important;
    font-weight: normal !important;
  }
  &__lighter-text {
    @include padding-top(10px);
  }
}

@media only screen and (max-width: 769px) {
  .bigredtext-smaltext {
    &__bigheader {
      h2 {
        line-height: normal;
      }
    }
    &__darker-text {
      @include margin-top(20px);
    }
  }
}
