.footer-contact {
  width: 100%;
  height: 345px;
  position: relative;
  display: flex;
  align-items: center;
  background: linear-gradient(214.43deg, #d1291c 0%, #ad2217 100%);
  background-position: center;
  background-size: cover;
  clip-path: ellipse(75% 56% at 50% 59%);

  &--dark {
    background-color: $blackBackGroundColor !important;
  }
  &__text-wrapper {
    &-title {
      @include font-size($headerText30);
      letter-spacing: 0;
      line-height: 38px;
      color: $toolbarTextGray;
      padding-bottom: 16px;
      font-weight: $fontWeight400;
    }
    &-title > * {
      @include font-size($headerText30);
      letter-spacing: 0;
      line-height: 38px;
      color: $toolbarTextGray;
      margin: 0px;
    }
    &-text,
    p {
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
      color: $toolbarTextGray;
    }
    &-text > * {
      @include font-size($smalHeaderText);
      letter-spacing: 0 !important;
      line-height: 27px !important;
      color: $toolbarTextGray !important;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    a {
      @include font-size($smalHeaderText);
      font-weight: $fontWeight500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .footer-contact {
    height: 270px;
  }
}
@media screen and(max-width:768px) {
  .footer-contact {
    &__button-wrapper {
      margin-top: 20px;
    }
  }
}

@media screen and(max-width:767px) {
  .footer-contact {
    &__text-wrapper {
      text-align: center;
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
@media screen and(max-width:450px) {
  .footer-contact {
    clip-path: none;
    height: auto;
    padding: 20px 0px;
  }
}
