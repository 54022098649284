.two-column {
  overflow: hidden;
  &__right {
    @include flexPosition(flex-end, unset, false);
    flex-wrap: wrap;
    .intro,
    .bullet {
      padding-left: 40px;
    }
    .text-title {
      width: 450px;
    }
  }
  .flex-start {
    justify-content: flex-start;
  }
  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
  }

  .bullet {
    &:not(:first-child) {
      @include margin-top(50px);
    }
  }

  section {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.ingress {
  position: relative;
  .row {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 1px;
      background-color: $borderColor;
    }
  }
  .two-column__right {
    padding: 25px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .two-column {
    &__right {
      justify-content: start;
    }
    .intro,
    .bullet {
      padding-left: 0px;
    }
  }
  .ingress {
    .two-column__right {
      border-top: 1px solid $borderColor;
      margin-top: 0px;
      padding: 15px;
    }
    .row {
      &::before {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .two-column {
    &__right {
      justify-content: start;
    }
  }
}
