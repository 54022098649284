.mobil__header {
  display: none;
  background-color: $lightBackgroundColor;
  .mobil__navbar {
    padding: 10px 15px;
    display: flex;
    width: 100%;
    position: relative;
    height: 71px;

    &__logo {
      display: flex;
      align-items: center;
      z-index: 1;
      height: 95%;
      padding-left: 30px;
      img {
        width: 100px;
      }
    }
    &__wrapper {
      position: absolute;
      width: auto;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto 0 auto;
      @include flexPosition(unset, center, false);
      .responsive-container {
        display: flex;
        padding-right: 30px;

        form {
          @include flexPosition(flex-end, unset, false);
        }
      }
      .responsive-container,
      .responsive-searchfield {
        @include flexPosition(flex-end, center, false);
      }
      .responsive-container {
        position: absolute;
        right: 5px;
        width: 30%;
        .search-button,
        .language-button {
          display: block;
          margin-right: 1.5rem;
        }
        .search-button {
          cursor: pointer;

          &::after {
            content: "\f002";
            font-family: "Font Awesome\ 5 Free";
            display: block;
            font-weight: $fontWeight600;
            font-size: 22px;
          }
        }
        .language-button {
          cursor: pointer;

          &::after {
            content: "\f7a2";
            font-family: "Font Awesome\ 5 Free";
            display: block;
            font-weight: $fontWeight600;
            font-size: 22px;
          }
        }
        .menu-button {
          display: block;
          div {
            width: 25px;
            height: 3px;
            background-color: black;
            margin: 5px;
            transition: all 200ms ease-in;
          }
        }
      }
      .responsive-searchfield {
        position: absolute;
        right: 20px;
        display: none;

        &__textfield {
          width: 70%;
          @include font-size($mediumTextSize);
          padding: 10px 10px 10px 10px;
          letter-spacing: 0;
          line-height: 22px;
          border: none;
          outline: none;
          background-color: $lightBackgroundColor;
        }
        .close-button {
          div {
            width: 25px;
            height: 3px;
            background-color: black;
            margin: 5px;
            transition: all 200ms ease-in;
          }
        }
      }
      .toggle {
        .menu-button__line1 {
          transform: rotate(-45deg) translate(-5px, 6px);
        }
        .menu-button__line2 {
          opacity: 0;
        }
        .menu-button__line3 {
          transform: rotate(45deg) translate(-5px, -6px);
        }
        .close-button__line1 {
          transform: rotate(-45deg) translate(-5px, 6px);
        }
        .close-button__line2 {
          opacity: 0;
        }
        .close-button__line3 {
          transform: rotate(45deg) translate(-5px, -6px);
        }
      }
    }
  }
  .language__dropdown {
    position: absolute;
    right: 0;
    top: 70px;
    left: 0;
    width: 105%;
  }

  .mobil__sidebar {
    position: absolute;
    top: 71px;
    right: 0px;
    height: calc(100vh - 71px);
    width: 90vw;
    background: #faf8f7;
    transform: translateX(100%);
    transition: transform 200ms ease-in;
    border-top: 1px solid #ede9e8;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    &--active {
      transform: translateX(0%);
    }

    &-items {
      @include flexPosition(unset, unset, true);
    }
    &-item {
      width: inherit;
      @include padding-top(20px);
      border-bottom: 1px solid $borderColor;
      display: flex;
      flex-wrap: wrap;

      a {
        @include font-size($smalHeaderText);
        color: $darkParagraphText;
        text-decoration: none;
        cursor: pointer;
        margin-left: 10px;
        padding-bottom: 20px;
      }
    }
    &-hasdropdown {
      position: relative;

      &::after {
        right: 30px;
        content: "\f107";
        position: absolute;
        margin-top: 2px;
        font-family: "Font Awesome\ 5 Free";
        display: block;
        font-weight: $fontWeight600;
        @include font-size($headerText30);
        transition: transform 200ms ease-in;
      }
      &--active {
        &::after {
          color: $headerTextRed;
          transform: rotate(180deg);
          transition: transform 200ms ease-in;
        }
      }
    }
    &__dropdown {
      width: 100%;
      display: none;
      height: auto;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      perspective: 1000;
      -webkit-perspective: 1000;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      li {
        padding: 20px 0px;
        background-color: #d9d9d9;
        border-bottom: 1px solid #fff;
      }
      a {
        margin-left: 10px;
        @include font-size($mediumTextSize);
        color: $darkParagraphText;
        text-decoration: none;
        cursor: pointer;
        display: block;
        padding: 0px;
      }
      &--title {
        h3 {
          text-transform: uppercase;
          @include font-size($mediumTextSize);
          line-height: normal;
          margin: 0px;
          margin-left: 10px;
          font-weight: $fontWeight600;
          color: $darkHeaderTextColor;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .mobil__header {
    display: block;
  }
}
