@mixin flexPosition($justify-position, $align-position, $isColumn){
    display: flex;
    justify-content: $justify-position;
    align-items: $align-position;

    @if $isColumn == true {
        flex-direction: column;
    }
}

@mixin button(){
    position: relative;
    z-index: 1;
    @include font-size($mediumTextSize);
    padding: 18px 40px;
    text-decoration: none !important;
    font-weight: $fontWeight500;
    border: 2px solid $headerTextRed;
    @include flexPosition(center, center, false);
    cursor: pointer;
    text-align: center;
    line-height: normal
}

@mixin btnColors($textColor, $bgColor, $isTransform){
    color: $textColor !important;
    background-color: $bgColor !important;
    transition: 100ms ease-in;
    transition-delay: 150ms;

    @if $isTransform == false {
        &:hover{
            filter: brightness(110%);
        }
    }@else if $isTransform == true {
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color:lighten($bgColor,10%);
            z-index: -1;

            transform: scaleX(0);
            transform-origin: right;
            transition: transform 250ms ease-in;
        }
        &:hover::before{
            transform: scaleX(1);
            transform-origin: left;
        }
    }

}

