.progess-steps {
  position: relative;
  @include flexPosition(unset, center, false);
  &__container {
    text-align: center;
    width: 18%;
    padding: 0px 10px;

    &-tile {
      color: $headerTextRed;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
    }

    &-text {
      margin-top: 10px;
    }
  }
  &::before {
    display: block;
    content: "";
    border-top: 2px solid #d1291c;
    width: 14%;
  }
  &::after {
    display: block;
    content: "";
    border-top: 2px solid #d1291c;
    width: 16%;
  }

  &__middle-line {
    width: 8%;
    height: 2px;
    &::before {
      display: block;
      content: "";
      border-top: 2px solid #d1291c;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .progess-steps {
    flex-direction: column;
    &__container {
      padding: 10px 0px;
      width: 40%;
    }

    &::before,
    &::after {
      display: none;
    }

    &__middle-line {
      width: auto;
      height: 50px;
      &::before {
        border-top: none;
        border-left: 2px solid #d1291c;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .progess-steps {
    flex-direction: column;
    &__container {
      width: 90%;
    }
  }
}
