.pdf-modal {
  width: 786px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: $lightBackgroundColor;
  z-index: 2000;
  top: 30%;
  .container {
    @include padding-top(40px);
    @include padding-bottom(40px);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__text-column {
    @include flexPosition(space-between, unset, true);
    min-height: 240px;
  }
  &__container {
    .container {
      padding: 0%;
    }

    .col-8 {
      @include flexPosition(space-between, unset, true);
    }

    &-title {
      color: $headerTextRed;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      margin-bottom: 10px;
    }
    &-select-language {
      margin-top: 30px;
      span {
        padding-bottom: 10px;
        margin-left: 3px;
        color: $darkParagraphText;
        @include font-size($smalTextSize);
        font-weight: $fontWeight600;
        letter-spacing: 2px;
        line-height: 18px;
        display: flex;
        &::before {
          content: "\f57d";
          font-family: "Font Awesome\ 5 Free";
          display: block;
          padding-top: 2px;
          font-weight: $fontWeight600;
          padding-right: 5px;
          @include font-size($mediumTextSize);
          color: #544c4c;
        }
      }
      &-select {
        select {
          background: #fff;
          width: 100%;
          padding: 10px;
          border-color: $borderColor;
          font-size: 16px;
          @include font-size($mediumTextSize);
          cursor: pointer;
        }
      }
    }
    &-buttons {
      width: 100%;
      @include flexPosition(unset, center, false);
      flex-wrap: wrap;
      div {
        margin-top: 20px;
        cursor: pointer;
        flex-grow: 1;
        &:nth-child(2) {
          margin-right: 5px;
          margin-left: 5px;
        }
      }
      a {
        cursor: pointer;
      }
      &-read,
      &-download,
      &-order {
        @include flexPosition(unset, center, false);
      }
      &-read {
        font-weight: $fontWeight500;
        border: 2px solid $headerTextRed;

        &::after {
          content: "\f518";
          font-family: "Font Awesome\ 5 Free";
          display: block;
          font-weight: $fontWeight600;
          margin-left: 12px;
          font-size: 14px;
          color: #fff;
        }
      }
      &-download {
        font-weight: $fontWeight500;
        border: 2px solid $headerTextRed;

        &::after {
          content: "\f063";
          font-family: "Font Awesome\ 5 Free";
          display: block;
          padding-top: 2px;
          font-weight: $fontWeight600;
          margin-left: 12px;
          font-size: 14px;
          color: #fff;
        }
      }
      &-order {
        background: #fff;
        font-weight: $fontWeight500;
        color: $darkGreyColor !important;
        border-color: #fff !important;

        &::after {
          content: "\f0e0";
          font-family: "Font Awesome\ 5 Free";
          display: block;
          padding-top: 2px;
          font-weight: $fontWeight600;
          margin-left: 15px;
          @include font-size($mediumTextSize);
          color: $mediumDarkTextColor;
        }
      }
    }
  }
  &__close {
    position: absolute;
    display: block;
    right: 24px;
    top: 15px;
    cursor: pointer;
    span {
      &::after {
        content: "\f00d";
        font-family: "Font Awesome\ 5 Free";
        display: block;
        padding-top: 2px;
        font-weight: $fontWeight600;
        margin-left: 15px;
        @include font-size(24px);
        color: $headerTextRed;
      }
    }
  }
}

.pdf-modal {
  @media only screen and (max-width: 769px) {
    width: 92%;
    height: auto;

    &__container-buttons {
      flex-wrap: wrap;
      justify-content: center;
      .btn-container{
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100%;
        margin-top: 15px;
      }
      a {
        @include flexPosition(center, unset, false);
      }
    }
  }
}

.pdf-modal {
  @media only screen and (max-width: 450px) {
    width: 101vw;
    height: 80%;
    top: 0;
  }
}
