.nolato-checkbox{
    position: relative;
    display: inline-block;
    &__title{
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
    }
    
    input{
       appearance: none;
       -webkit-appearance: none;
        &:checked ~.nolato-checkbox__checkmark{
            background-color: $headerTextRed;
            color: $whiteTextColor;
        }
    }
    &__checkmark{
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #E0DADA;
        text-align: center;
        border-radius: 3px;
        margin: 0 4px;
        line-height:20px;
        transition: all .5s;
        &::after {
            content: "\f00c";
            font-family: $FontAwesome;
            @include flexPosition(center, center, false);
            font-weight: $fontWeight600;
            font-size: 12px;
            height: inherit;
        }   
    }

}
.form__error{
    position: absolute;
    display: none;
    border: 1px solid $headerTextRed;
    color: #fff;
    background: $headerTextRed;;
    padding: 7px 10px;
    @include font-size ($mediumSmalTextSize);
    font-weight: $fontWeight600;
    letter-spacing: 1px;
    z-index: 1;
    top: 106%;
    &::after{
        left: 2px;
        position: absolute;
        top: -10px;
        font-family: 'Font Awesome\ 5 Free';
        display: block;
        content: " ";
        border-bottom: 11px solid $headerTextRed;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }
}