.sitecore__forms {
  width: 100%;
  p:first-child {
    margin-bottom: 40px !important;
  }

  p {
    margin: 20px 0px;
  }
  label {
    @include flexPosition(unset, center, false);
    input {
      margin-right: 10px;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include margin-bottom(20px);
    }

    input,
    textarea,
    button,
    select {
      @include margin-bottom(20px);
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    textarea,
    select {
      cursor: pointer;
      width: 100%;
      max-width: 400px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
    textarea {
      width: 100%;
    }
    input[type="file"] {
      max-width: 400px;
    }
    input[type="submit"] {
      max-width: 400px;
      height: auto;
      @include font-size($mediumTextSize);
      position: relative;
      z-index: 1;
      padding: 18px 40px;
      display: inline-block;
      border: 2px solid $headerTextRed;
      cursor: pointer;
      text-decoration: none !important;
      font-weight: $fontWeight500;
      background-color: $headerTextRed;
      color: #fff !important;
      transition: all 0.2s;
      white-space: nowrap;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #e93e32;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 250ms ease-in;
      }
      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
    input[type="checkbox"],
    input[type="checkbox"] {
      height: auto !important;
      margin: 0px;
      margin-right: 10px;
    }
    select {
      height: 40px;
      padding: 0px 16px;
      border: 1px solid $borderColor;
      outline: none;
    }
    select,
    option {
      color: $GrayColor;
      @include font-size($mediumTextSize);
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
