.shares-links {
  &.mf-add-extra-margin-bottom{
    margin-bottom: 30px;
  }
  &__row {
    padding: 0px !important;
    @include margin-top(5px);
  }
  &__iframe,
  table {
    width: 100%;
    height: auto;
  }
  .shares-links__title {
    h2 {
      @include font-size($largeHeaderText);
      @include margin-bottom(10px);
    }
    h3 {
      text-transform: uppercase;
      @include font-size($smalTextSize);
      text-align: center;
      font-weight: $fontWeight600;
      letter-spacing: 1.5px;
      color: $headerTextRed;
    }
  }
  &__container {
    @include flexPosition(unset, center, false);
    flex-wrap: wrap;
    &-items {
      @include margin-top(20px);
      width: 100%;
      a {
        width: 100%;
        .shares-links__container-items-item {
          border: 1px solid $borderColor; //no bottom border on third child
        }
      }

      &-item {
        @include flexPosition(space-between, center, false);
        padding: 21px 15px;
        &:hover {
          p {
            color: $headerTextRed;
          }
          .text-with-black-arrow-right {
            &::after {
              color: $headerTextRed;
            }
          }
        }
      }
    }
  }
  .mf-calendar-table{
    tr {
      border-bottom: 1px solid $headerTextRed;
    }
  }
  .mfn-content {
    margin: 0 !important;
  }
  .mf-table {
    width: 100%;
    tr {
      border-bottom: 1px solid $headerTextRed;
    }
    th {
      @include font-size($smalTextSize);
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
    }
    td {
      @include font-size($smalTextSize);
      padding: 0.2rem;
      font-feature-settings: 'tnum';
    }
    th,
    td {
      text-align: right;
    }
    td,
    th {
      &:first-child {
        text-align: left !important;
      }
    }
  }

  .mfn-archive{
    
    .mfn-archive-year{
     &::before
      {
        content: '';
        display: block;
        height: 75px;
      
     
     }
      
    }
   
    tr {      
      border-bottom: 1px solid $headerTextRed;
      td{
        padding: 5px 0; 
        &:last-child {
          min-width: 50px;
          width:80px;
        }
      }
      .mfn-archive-event-title{
        
        @include font-size($mediumTextSize);
        line-height: 21px;       
        color:$darkHeaderTextColor;
      }
      .mfn-archive-event-date{
        color:$darkParagraphText;
        @include font-size($smalTextSize);
      }
      .mfn-archive-item{
        img{
          width: auto;
          pointer-events: all;
        }
      &.mfn-archive-item-type-release{
        img{
          content:url("../assets/icons/document-icon.svg");
        }
      }
      &.mfn-archive-item-type-report-pdf{
        img{
          content:url("../assets/icons/rapport-icon.svg");
        }
      }
      &.mfn-archive-item-type-presentation{
        img{
          content:url("../assets/icons/presentation-icon.svg");
        }
      }
      &.mfn-archive-item-type-webcast{
        img{
          content:url("../assets/icons/film-icon.svg");
        }
      }
      &.mfn-archive-item-type-transcript{
        img{
          content:url("../assets/icons/transcript-icon.svg");
        }
      }
      &.mfn-archive-item-type-report-esef {
        img {
          content:url("../assets/icons/report-esef-icon.svg");
        }
      }
        }
     
    }
    .mfn-archive-header{
      background-color: transparent;
      color:$darkHeaderTextColor;
      font-weight: bold;
      line-height: 21px;
      padding-bottom: 5px;
      @include font-size($smalTextSize);
      &:not(:first-child) {
        padding-left: 0.5rem;
      }
      &:first-child {
        color:$headerTextRed;
        font-weight: normal;
        @include font-size($largeHeaderText);
      }
      &:last-child {
       // text-align: right;
       padding-left: 0;
       @media (max-width: 991px) {
        padding-left: 0.5rem;
       }
      }
    }
  }

 


  #mf_calendar_top_buttons {
    display: none;
  }
  #mf_calendar_buttons
   {
    padding-top: 20px;
  }
  button {
    @include btnColors(#fff, $headerTextRed, true);
    @include button;
    padding: 10px 30px 10px 30px;
    display: inline;

    &:nth-child(even) {
      @include btnColors($headerTextRed, #fdf7f6, true);
      margin: 0 0.5rem;
    }
    @media (max-width: 450px) {
      margin: 0 !important;
      margin-top: 1rem !important;
      width: 100%;
    }
  }
  #mfn-clear-button-0 {
    color: $headerTextRed;
    border: none;
    background: none;
    &::before {
      display: none;
    }
  }

  #mf_30427DDFC36046D88FE69CCB164D57A3, #mf_FBD34F32CC454833AF69FA692C094D06{
    table{
      table-layout: fixed!important;
    }
  }

  .mf-volume-dates{
    @include font-size(8px);
  }
  .mf-volume-info-text{
    display: none;
  }
}

@media (max-width: 991px) {
  .shares-links {
    &__container {
      margin-top: 20px;
    }
    div[class*="col"] {
      padding: 0px 15px;
      border: none;
    }
    .datablocks-holder,
    #mf_calendar,
    .mfn-archive-year
     {
      overflow-x: auto !important;
    }
    &__container {
      &-text {
        padding: 0;
      }
    }
  }
}
.mfnLatestReports{
  .shares-links__row{
    margin-top: 0;
  }
  .mfn-archive-event-heading{
    @include font-size($smalHeaderText);
    line-height: normal;
    color: $headerTextRed;
    margin-bottom: 15px;
  }
  .mfn-archive-event-title{
    @include font-size($largeHeaderText);
      @include margin-bottom(10px);
  }
  .mfn-archive-event-date{
    color: $darkParagraphText;
    @include font-size($smalTextSize);
    font-weight: $fontWeight300;
    letter-spacing: 0;
    line-height: 16px;
  }

  .mfn-archive-item-content{
    height: auto;
    border: 1px solid $borderColor;
    padding: 20px 15px;
    border-bottom: none;
    min-height: 75px;
    display: flex;
    flex-direction: row;
    &:last-child {
      border-bottom: 1px solid $borderColor;
    
    }
    transition: all 0.3s ease-in;
   
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    .mfn-archive-item-title    {
      color: $darkParagraphText;
      width: 95%;
      @include flexPosition(unset, center, false);
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: normal;
      line-height: normal;
  }
  .mfn-archive-item-icon{
    width:auto;
    padding-right:10px;
    @include flexPosition(unset, center, false);
  }
  .mfn-archive-item-placeholder{
    display: inline-block;
 
  width: 5%;
    @include flexPosition(flex-end, center, false);
  &::after {
    content: "\f054";
    font-family: $FontAwesome;
    display: inline-block;
    font-weight: $fontWeight600;
    padding-left: 10px;
    font-size: 12px;
    color: $headerTextRed;
  }
  }
  
  
 
}
.mfn-archive-item{
  img{
    width: auto;
    pointer-events: all;
  }
  &.mfn-archive-item-type-release{
    img{
      content:url("../assets/icons/document-icon.svg");
    }
  }
  &.mfn-archive-item-type-report-pdf{
    img{
      content:url("../assets/icons/rapport-icon.svg");
    }
  }
  &.mfn-archive-item-type-presentation{
    img{
      content:url("../assets/icons/presentation-icon.svg");
    }
  }
  &.mfn-archive-item-type-webcast{
    img{
      content:url("../assets/icons/film-icon.svg");
    }
  }
  &.mfn-archive-item-type-transcript{
    img{
      content:url("../assets/icons/transcript-icon.svg");
    }
  }
  &.mfn-archive-item-type-report-esef {
    img {
      content:url("../assets/icons/report-esef-icon.svg");
    }
  }   
    }
  
}

