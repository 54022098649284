.backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 1s;
  @include flexPosition(center, center, false);
  opacity: 0;
  z-index: 1700;
}

.fullImage {
  width: 75%;
  height: 75%;
  max-height: 75%;
  animation: slide-up-fade-in 0.6s ease-in;
  background-repeat: no-repeat;
  background-position: center;
}

.alternativeDiv {
  height: 75%;
  width: 5%;
  background-color: transparent;
  animation: slide-up-fade-in 0.6s ease-in;
}

.download {
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  top: 10%;
}

.download::after {
  content: "\f56d";
  position: absolute;
  font-family: "Font Awesome\ 5 Free";
  font-size: 40px;
  font-weight: 900;
  left: 10px;
  color: white;
  width: 100%;
  height: 100%;
  top: 10%;
}

.closeIcon {
  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.closeIcon::after {
  position: absolute;
  content: "\f00d";
  font-family: "Font Awesome\ 5 Free";
  font-size: 40px;
  font-weight: 900;
  color: white;
  top: 0;
  left: 10px;
  width: 70%;
  height: 100%;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 30px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
