.nav__navbar-dropdown {
  width: 1650px;
  position: absolute;
  display: none;
  max-height: 1000px;
  z-index: 1040;
  background-color: $whiteMediumBackgroundColor;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  left: 50%;
  transform: translateX(-50%);
  top: 45px;

  &__shrink {
    top: 36px;
  }

  &--active {
    animation: nolatoFadeIn 500ms ease-in;
    display: block;
    transform: scale(1);
  }
  &--has-icons {
    .nav__dropdown-body-text {
      img {
        display: block !important;
      }
      span {
        padding-left: 10px !important;
      }
    }
  }
  .row {
    width: 100%;
  }
  .container {
    height: 100%;
    max-width: 1400px !important;
    .nav__dropdown-header-row {
      padding: 32px 0 28px;
      align-items: center;
      a {
        display: flex;
      }
      h2 {
        @include font-size($mediumHeaderText);
        letter-spacing: 0;
        line-height: 32px;
        color: $headerTextRed;
        font-weight: $fontWeight500;
        margin: 0px;
        display: flex;
        transition: all 250ms ease-in;
        &:hover {
          color: $headerTextRed !important ;
          &::after {
            color: $headerTextRed !important ;
            display: block;
          }
        }
        &::after {
          display: none;
          margin-left: 10px;
          content: "\f054";
          font-family: "Font Awesome\ 5 Free";
          font-weight: $fontWeight600;
          padding-left: 5px;
          @include font-size(16px);
        }
      }
      .nav__dropdown-close-container {
        @include flexPosition(flex-end, center, false);
      }
      .nav__dropdown-close-section {
        @include flexPosition(flex-end, unset, false);
        cursor: pointer;
        &:hover {
          span {
            color: $headerTextRed;
          }
        }
        span {
          @include font-size($mediumTextSize);
          font-weight: $fontWeight300;
          letter-spacing: 0;
          line-height: 22px;
          color: $darkParagraphText;
          @include flexPosition(unset, center, false);
          &::after {
            content: "\f00d";
            display: block;
            margin-left: 10px;
            font-family: "Font Awesome\ 5 Free";
            font-weight: $fontWeight600;
            color: $headerTextRed;
            @include font-size($mediumTextSize);
          }
        }
      }
    }
    .nav__dropdown-body-row {
      h3 {
        @include font-size($smalTextSize);
        color: $darkParagraphText;
        margin-top: 20px;
        padding-left: 3px;
        font-weight: $fontWeight600;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
      .nav__dropdown-body-wrapper {
        width: 100%;
        border-bottom: 1px solid $borderColor;
        a {
          @include font-size($mediumTextSize);
          height: 29px;
          position: relative;
          letter-spacing: 0;
          color: $darkParagraphText;
          @include flexPosition(unset, center, false);
          overflow: hidden;
          margin: 28px 0px 12px 1px;
          &:hover {
            color: $headerTextRed !important ;
            &::after {
              color: $headerTextRed !important ;
              display: block;
            }
          }
          &::after {
            display: none;
            margin-left: 10px;
            content: "\f054";
            font-family: "Font Awesome\ 5 Free";
            padding-top: 2px;
            font-weight: $fontWeight600;
            padding-left: 5px;
            @include font-size(12px);
            top: 42%;
          }
        }
      }
      .active {
        span,
        a {
          color: $headerTextRed;
        }
      }
    }
    .nav__dropdown-bottom-section {
      padding: 45px 15px 40px 0;
    }
  }
}
