.contact-modal {
  &__popup {
    position: fixed;
    z-index: 1700;
    height: 100vh;
    width: 50vw;
    max-width: 700px;
    top: 0;
    right: 0;
    padding: 75px 40px !important;
    background-color: $whiteBackgroundColor;
    overflow-x: hidden;
    transition: transform 0.3s ease;
    transform: translateX(100%);
    .contactForm__contactCards {
      .contact-card {
        width: 50%;
      }
      .contact-cardList {
        .contact-card__container {
          align-items: center;
          width: 50%;
        }
      }
    }
  }
  &__title {
    h2 {
      color: $darkHeaderTextColor !important;
      margin-bottom: 40px;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    display: block;
    right: 42px;
    top: 0px;
    span {
      &::after {
        content: "\f00d";
        font-family: "Font Awesome\ 5 Free";
        display: block;
        padding-top: 2px;
        font-weight: $fontWeight600;
        margin-left: 15px;
        @include font-size(40px);
        color: $headerTextRed;
      }
    }
  }
  &__confirm-container {
    margin-top: 20px;
  }
  .form-row {
    textarea {
      height: 130px;
      background-color: $lightBackgroundColor;
    }
    input {
      height: 40px;
      background-color: $lightBackgroundColor;
    }

    .form-control {
      &:focus {
        border-color: $darkParagraphText;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.005),
          0 0 2px $darkParagraphText;
      }
    }
    label {
      color: $darkParagraphText;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
  &__agree-terms {
    width: 75%;
    input {
      height: 0px !important;
    }
    color: $darkParagraphText;
    @include font-size($mediumSmalTextSize);
    font-weight: $fontWeight400;
    text-transform: none !important;
    a {
      color: $headerTextRed;
      text-decoration-color: $darkParagraphText;

      &:hover {
        color: $headerTextRed;
      }
    }
  }

  &__robot,
  &__submit {
    margin: 20px 0px;
    p {
      margin-bottom: 18px;
    }
  }
  &__submit {
    @include flexPosition(flex-end, unset, false);
  }

  &__contact-person-title {
    h4 {
      color: $darkHeaderTextColor;
      @include font-size($largeHeaderText);
      letter-spacing: 0;
      line-height: 32px;
      padding: 20px 0px;
    }
  }
  .contact-cardList {
    padding: 0px 5px;
  }
  .contact-card__title {
    h3 {
      @include font-size($largeHeaderText);
    }
  }
}

.contact__required {
  &::after {
    content: "*";
    display: inline-block;
    margin-left: 4px;
    font-size: 16px;
    color: $headerTextRed;
  }
}

.ir-contact-form {
  .col-lg-12 {
    padding: 0px !important;
  }
  form {
    @media only screen and (max-width: 991px) {
      min-height: 641px;
    }
    @media only screen and (max-width: 991px) {
      min-height: 767px;
    }
  }
  .contact-modal__submit{
    justify-content: flex-start;
  }
}

.contactForm3 {
  .contact-modal__robot {
    padding-bottom: 12px;
  }
  &__title {
    &-header {
      margin-bottom: 20px;
      font-weight: $fontWeight400;
      &--text {
        color: $headerTextRed;
        @include font-size($headerText30);
        letter-spacing: 0;
        margin: 0px;
      }
    }
  }
  input {
    height: 40px;
  }
  textarea {
    min-height: 200px;
  }
  textarea,
  input {
    background-color: $lightBackgroundColor;
  }
  .col-lg-7 {
    border-bottom: 1px solid $borderColor;
  }
}
.contactLoading__container {
  display: none;
  .contact__onload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0.9;
    @include flexPosition(center, center, true);
    background-image: linear-gradient(
      45deg,
      #ffffff 23.68%,
      #f7f7f7 23.68%,
      #f7f7f7 50%,
      #ffffff 50%,
      #ffffff 73.68%,
      #f7f7f7 73.68%,
      #f7f7f7 100%
    );
    background-size: 26.87px 26.87px;
    h3 {
      margin: 30px 0px;
      font-weight: $fontWeight500;
    }
    &-responseMessage {
      font-weight: $fontWeight500;
      @include font-size($smalHeaderText);
    }
  }
}
.contact__radiobuttons {
  display: flex;
  .nolato-radiobutton {
    margin-right: 20px;
  }
  &__item-text {
    color: $darkParagraphText;
    @include font-size($mediumTextSize);
    letter-spacing: 0;
  }
}
.contact-modal {
  @media only screen and (max-width: 1400px) {
    &__popup {
      left: auto;
      right: 0px;
    }
  }
  @media only screen and (max-width: 1025px) {
    width: 90%;
  }

  @media only screen and (max-width: 769px) {
    width: 100%;
    height: 100%;
    overflow: scroll;
    &__submit {
      justify-content: flex-start;
    }

    &__agree-terms {
      width: 100%;
    }
    .contactForm__contactCards {
      .contact-card {
        width: 100%;
      }
      .contact-cardList {
        .contact-card__container {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}
.contact-modal__popup {
  @media only screen and (max-width: 769px) {
    padding: 26px 15px !important;

    .contact-modal__close {
      top: 25px;
      right: 20px;
    }
  }
}

.thank-you-message,
.error-message {
  display: none;
  height: 63%;
  width: 100%;
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__responseHeadline {
    @include font-size($bigHeaderText);
  }
  &__responseMessage {
    padding-top: 20px;
    font-weight: 500;
    @include font-size($smalHeaderText);
  }
  &__button {
    margin: 20px;
  }
}
.error-message {
  &__responseHeadline {
    color: $headerTextRed;
  }
}
.extranet-form {
  width: 100%;
}

//model finance selector

.mfn-select,
#mf_toDate,
#mf_fromDate,
#mf_numberOfShares,
#mf_value {
  word-wrap: normal;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  @media only screen and (max-width: 769px) {
    display: block;
    width: 100%;
  }
}
.mfn-select {
  max-width: 580px;
  width: 100%;
  display: block;
}
.mfn-clear {
  margin: 1rem 0rem;
  @media only screen and (max-width: 600px) {
    padding: 0 15px;
  }
}
