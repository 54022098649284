.breadcrumbs {
    display: flex;
    gap: 10px;
    padding: 40px 0 0 0;

    @media (max-width: $breakpoint-cellphone) {
        display: none;
    }

    &__home {
        background-image: $homeGray;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 13px;
    }

    & ul {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;   
    }

    & li {
        position: relative;
        margin-right: 20px;

        & a {
            //font-family: "Inter Medium"; 
            color: #989898;
            font-size: 15px;

            &:hover {
                color: $lightRedBackgroundColor;
                text-decoration: underline;
            }
        }

        &:not(:last-child):after {
            content: '';
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #989898;
        }

        &.active {
            & a {
                color: #989898;

                &:hover {
                    color: $lightRedBackgroundColor;
                    text-decoration: underline;
                }
            }
            
        }
    }

}