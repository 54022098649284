.cardsection {
  position: relative;
  &__container {
    position: relative;
    overflow: hidden;
    transition: ease 2;
  }
  &__overlay {
    position: absolute;
    width: 100%;
    background: linear-gradient(
      179.87deg,
      rgba(245, 245, 245, 0) 0%,
      rgba(255, 255, 255, 0.9) 66.68%,
      #ffffff 100%
    );
    z-index: 60;
    bottom: 0px;
    left: 0px;
  }
  &__wrapper {
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.2s ease-in;
    &:hover {
      .cardsection__textlink-container-text {
        color: $headerTextRed !important;
      }
    }
  }
  div[class*="col"] {
    &:nth-child(n + 5) {
      @include margin-top(30px);
    }
  }

  &__image {
    height: 180px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__header {
    @include flexPosition(space-between, unset, false);
    flex-wrap: wrap;
    @include margin-bottom(24px);

    &-text {
      width: auto;
      h2 {
        @include font-size($mediumHeaderText);
        color: $darkHeaderTextColor;
        letter-spacing: 0;
        line-height: 40px;
        margin: 0px;
      }
    }
  }
  &__info {
    width: auto;
    @include flexPosition(flex-end, center, false);
    a {
      text-transform: uppercase;
    }
  }

  &__textlink {
    width: 100%;
    padding: 15px 3px 15px 4px;
    border-bottom: 1px solid #ede9e8;
    @include flexPosition(space-between, unset, false);

    &-wrapper {
      display: flex;
      &::before {
        content: attr(data-cardSectionIcon);
        font-family: $FontAwesome;
        font-weight: $fontWeight600;
        display: block;
        margin-right: 10px;
        font-size: 16px;
        color: $headerTextRed;
      }
    }
    &-container {
      height: 100%;
      width: 16px;
      margin-right: 10px;
      @include flexPosition(unset, center, false);
    }
    span {
      color: $darkHeaderTextColor;
      @include font-size($smalHeaderText);
      letter-spacing: 0;
      line-height: 27px;
    }
    img {
      max-width: 16px;
      filter: $svgRedColor;
    }
  }
  .collapsebutton {
    height: 30px;
    @include margin-top(30px);
    @include flexPosition(center, unset, false);
    display: none;
    width: 100%;
    z-index: 50;

    &-section {
      @include flexPosition(unset, center, false);
      white-space: nowrap;
      cursor: pointer;
      padding: 10px 15px 10px 20px;
    }
    &-text {
      color: $headerTextRed;
      letter-spacing: 0;
      line-height: 27px;
      text-transform: uppercase;
      @include font-size($mediumSmalTextSize);
      font-weight: $fontWeight600;
      transition: transform 250ms ease-in;
      &::after {
        content: "\f078";
        font-family: $FontAwesome;
        display: inline-block;
        font-weight: $fontWeight600;
        padding-left: 15px;
        @include font-size($smalTextSize);
        color: $headerTextRed;
      }
    }

    &--close {
      &::after {
        transform: rotate(180deg);
        padding-right: 15px;
        padding-top: 2px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .cardsection {
    &__header-text,
    &__info {
      width: 100%;
      justify-content: flex-start;
    }
    &__header {
      padding: 0px 15px;
      margin-top: 0px;
    }
  }
  div[class*="col"] {
    &:nth-child(n + 5) {
      @include margin-top(0px);
    }
  }
}
