.text-and-optional {
  &__container {
    @include margin-bottom(32px);
  }
  &__title,
  #article-container .mfn-title {
    line-height: normal;
    color: $darkHeaderTextColor;
    @include font-size($bigHeaderText);
    letter-spacing: 0;
    margin-bottom: 0.5rem;
  }
  &__sub-title,
  #article-container.mfn-date {
    line-height: normal;
    color: $darkParagraphText;
    font-size: $mediumSmalTextSize;
    letter-spacing: 0;
  }

  .nolato-component {
    @include margin-top(89px);
  }

  .ir-order-form {
    margin-top: 0px !important;
    .thank-you-message__container {
      display: block;
    }
    .container {
      padding: 0px !important;
    }
    div[class*="col-lg"] {
      padding: 0px !important;
    }

    .contact-modal__submit {
      display: block;
    }
  }

  .right__column {
    h3 {
      color: $headerTextRed;
    }
    .shares-links {
      margin-top: 30px !important;

      col,
      .col-lg-12 {
        padding: 0px !important;
      }
    }

    .link-card__container {
      height: auto !important;
      min-height: 90px;
    }
  }
  #article-container {
    .mfn-content {
      @include margin-top(16px);
    }
  }

  .mfn-tags-list {
    display: flex;
    .mfn-tag + .mfn-tag {
      margin-left: 10px;
    }
  }

  .mfn-date {
    color: $darkParagraphText;
    font-family: Inter;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
  .mfn-about,
  .mfn-attachment {
    display: none;
  }
  .mfn-regulatory{
    margin-top:1rem;
  }
  .mfn-footer > * {
    @include font-size($smalTextSize);
  }
  .mfn-body {
    &:not(h1, h2, h3, h4) {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
    }

    p{
      strong + br{
        display: none;
      }
    }
  }
  .mfn-body > * {
    &:not(h1, h2, h3, h4) {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  .mfn-heading-1 {
    @include padding-top(45px);
  }
}
