.footer-wrapper {
  position: relative;

  .footer {
    background-color: $brownBackgroundColor;
    .footer-container {
      @include padding-top(100px);
      @include padding-bottom(60px);
    }
    .footer-address,
      .footer-address a{
        color: $whiteTextColor;
        @include font-size($smalHeaderText);
        font-weight: $fontWeight300;
        letter-spacing: 0;
        line-height: 27px;
        transition: all 0.2s;
        text-align: right;
        @media only screen and (max-width: 767px) {
          text-align: left;
        }
     
    }
    .footer-address a{     
      &:hover {
        color: $headerTextRed;
      }
   
  }
  .footer-address {     
    @media only screen and (max-width: 767px) {
      @include padding-top(31px);
    }
 
}
    &__logo-container {
      width: 80%;

      img {
        max-width: 60%;
      }
    }
    &__slogan-container {
      @include padding-top(20px);
      @include padding-bottom(48px);
      span {
        @include font-size($smalTextSize);
        font-weight: $fontWeight600;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 18px;
        color: $whiteTextColor;
      }
    }

    &__social-media {
      @include flexPosition(flex-end, unset, false);

      &-icon {
        position: relative;
        margin-right: 16px;
        background-color: $headerTextRed;
        &:before {
          font-family: $FontAwesomeBrands;
          @include font-size($mediumTextSize);
          text-align: center;
          width: 40px;
          height: 40px;
          text-decoration: none;
          display: block;
          color: $whiteTextColor;
          line-height: 40px;
          font-weight: $fontWeight900;
        }
        &[href*="facebook"]:before,
        &-facebook:before {
          content: "\f39e";
        }
        &[href*="twitter"]:before,
        &-twitter:before {
          content: "\f099";
        }
        &[href*="linkedin"]:before,
        &-linkedin:before {
          content: "\f0e1";
        }
        &[href*="instagram"]:before,
        &-instagram:before {
          content: "\f16d";
        }
        &[href*="youtube"]:before,
        &-youtube:before {
          content: "\f16a";
        }
        &[href*="vimeo"]:before,
        &vimeo:before {
          content: "\f27d";
        }
      }
    }
    &__menu {
      border-top: 1px solid $footerBorderColor;
      border-bottom: 1px solid $footerBorderColor;
      @include padding-top(30px);
      @include padding-bottom(30px);
      &__avenue{
        align-items: flex-end;
        display: flex;
      }
      ul {
        list-style: none;
        display: flex;
        li {
          margin-right: 30px;
          span {
            color: $whiteTextColor;
            @include font-size($smalHeaderText);
            font-weight: $fontWeight300;
            letter-spacing: 0;
            line-height: 27px;
            transition: all 0.2s;
            &:hover {
              color: $headerTextRed;
            }
          }
        }
      }
    }
    &__copyright {
      @include margin-top(48px);

      &-list {
        list-style: none;
        display: flex;
        li {
          margin-right: 20px;
          span {
            color: $whiteBackgroundColor;
            font-size: 15px;
            letter-spacing: 0;
            
            &:hover {
              color: $headerTextRed;
            }
          }
        }
      }
      &-owner {
        @include flexPosition(flex-end, unset, false);
        span {
          color: $whiteBackgroundColor;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    &__menu {
      ul {
        flex-direction: column;
      }
    }
    &__copyright {
      &-list {
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
      }
      &-owner {
        margin-top: 10px;
        justify-content: flex-start !important;
      }
    }
  }
}
