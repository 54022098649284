.card-image-links {
  &__row{
    margin-right: 0;
    margin-left: 0;
  }
  &__title {
    h2 {
      @include margin-bottom(15px);
    }
  }
  &__col {
    padding: 20px;
    padding-right: 30px;
    border: 1px solid $borderColor;
    a {
      display: flex;
      height: 100%;
    }
  }
  &__image {
    width: 50%;
    height: 230px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    position: relative;
    padding-left: 25px;
    width: 48%;

    &-title {
      h3 {
        color: $darkHeaderTextColor;
        @include font-size($largeHeaderText);
        letter-spacing: 0;
        line-height: 32px;
      }
    }
    &-text {
      max-height: 155px;
      overflow: hidden;
    }
    &-text > * {
      color: $darkParagraphText;
      @include font-size($mediumTextSize);
      font-weight: $fontWeight300;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0px;
    }
    &-link {
      width: 95%;
      position: absolute;
      bottom: 5px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
@media (max-width: 600px) {
  .card-image-links {
    &__col {
      padding: 0px;
      border: none;
    }
    a {
      flex-direction: column;
    }
    &__image,
    &__container {
      width: 100%;
    }
    &__image {
      height: 200px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &__container {
      padding: 20px 10px;

      &-link {
        position: unset;
        @include margin-top(20px);
        justify-content: flex-start;
        span {
          &::after {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .card-image-links {
    &__col:not(:first-child) {
      margin-top: 20px;
    }
  }
}
