.FAQ {
  &__text {
    &-title {
      h2 {
        color: $headerTextRed;
        text-transform: uppercase;
      }
    }
  }
  &__collapse {
    &-item {
      border: 1px solid $borderColor;
      border-radius: 2px 2px 0 0;
      padding: 30px;
      cursor: pointer;
      transition: all 0.3s ease-in;
      &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      }
    }
    &-row {
      display: flex;
    }
    &-title {
      width: 90%;
      p {
        color: $darkHeaderTextColor;
        @include font-size($smalHeaderText);
        letter-spacing: 0;
        line-height: 27px;
        font-weight: 400;
      }
    }
    &-button {
      width: 10%;
      @include flexPosition(flex-end, center, false);
      position: relative;
      &::after {
        content: "\f078";
        font-family: $FontAwesome;
        display: block;
        font-weight: $fontWeight600;
        font-size: 22px;
        color: $headerTextRed;
        transition: transform 300ms ease-in;
      }
      &--active {
        &::after {
          transform: rotate(180deg);
          transition: transform 300ms ease-in;
        }
      }
    }
    &-container {
      height: auto;
      margin-top: 20px;
      display: none;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      perspective: 1000;
      -webkit-perspective: 1000;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
  }
}

@media only screen and (max-width: 769px) {
  .FAQ {
    &__text-body {
      @include padding-bottom(40px);
    }
  }
}
